import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  /**
   * Revisa si el usuario se encuentra autenticado en el sitio
   */
  public isLoggedIn() {
    return localStorage.getItem('TOKEN') !== null;
  }

  /**
   * Elimina el token de la sesión
   */
  public logout() {
    localStorage.removeItem('TOKEN');
  }

  public getToken(): any {
    return localStorage.getItem('TOKEN');
  }

  public deleteToken() {
    localStorage.removeItem('TOKEN');
  }

  public setToken(token: any) {
    localStorage.setItem('TOKEN', JSON.stringify(token));
  }

  public setAccessToken(accessToken: any) {
    localStorage.setItem('ACCESS_TOKEN', JSON.stringify(accessToken));
  }

}
