
import { Component, ElementRef, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { User } from 'src/app/model/user';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ReportesService } from 'src/app/services/reportes/reportes.service';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})
export class ReportesComponent implements OnInit {

  @ViewChild('errorSwal') private errorSwal: SwalComponent;

  user: User;

  public show_dialog : boolean = false;
  public button_name : any = 'Generar';
  seleccion: number;
  deviceValue:number= 0;
  url: any;


  constructor(private localstorageService: LocalStorageService,
    private reportesServicio: ReportesService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit(){
    this.user = this.localstorageService.getUser();
    this.seleccion = this.deviceValue;
    if(this.seleccion === 0){
      this.show_dialog = false;
    }
  }

  onChange(deviceValue) {
    this.seleccion = parseInt(deviceValue);
    if(this.seleccion === 0){
      this.show_dialog = false;
    }
}

  exporExcel() {
    this.ngxService.start();
    if(this.seleccion === 0){
      this.show_dialog = false;

    }else{
    this.reportesServicio.generaExcel(this.user.idEmpresa, this.seleccion).subscribe(
     res => {

       console.log(res.body['url']);
       this.url = res.body['url'];
          this.show_dialog = !this.show_dialog;
          this.show_dialog = true;
        }, err => {
          this.ngxService.stop();
        }
      );
        this.ngxService.stop();
    }
}

  descargaArchivo() {
    this.ngxService.start();

        window.open(this.url, '_blank');
        this.ngxService.stop();
  }
}
