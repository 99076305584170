import { Entidad } from './Entidad';
export class CentroVO {
  idCentro: number;
  folio: number;
  nombre: string;
  cp: string;
  idEntidad: number;
  entidad: string;
  colonia: string;
  calle: string;
  noExterior: string;
  noInterior: string;
  longitude: number;
  latitude: number;
  idEstatus: number;
  estatus: string;
  totalTutores: number;
  totalPlanes: number;
  espaciosOcupados: number;
  espaciosOfertados: number;
  totalBecarios: number;
  user_modifica: string;
  idEmpresa: number;
  empresa: string;
  ultima_modificacion: Date;
  centro: string;
}
