import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { CentroVO } from 'src/app/model/CentroVO';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EntidadesService } from 'src/app/services/catalogos/entidades.service';
import { Entidad } from 'src/app/model/Entidad';

@Component({
  selector: 'app-evc-centros',
  templateUrl: './evc-centros.component.html',
  styleUrls: ['./evc-centros.component.scss']
})
export class EvcCentrosComponent implements OnInit {

  folioMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  cpMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

  @Input() editar: boolean;
  @Input() updateCentro: EventEmitter<CentroVO>;
  @Output() updateEvent = new EventEmitter();

  @ViewChild('closeDialog') closeDialog: ElementRef;

  centro: CentroVO;

  evcForm: FormGroup;
  submitedEvc = false;
  entidades: Entidad[];

  constructor(private formBuilder: FormBuilder,
    private entidadesService: EntidadesService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.ngxService.start();
    this.centro = new CentroVO();

    this.evcForm = this.formBuilder.group({
      fieldFolio: ['', Validators.required],
      fieldNombre: ['', Validators.required],
      fieldEntidad: ['', Validators.required],
      fieldColonia: ['', Validators.required],
      fieldCalle: ['', Validators.required],
      fieldNoExterior: ['', Validators.required],
      fieldNoInterior: [''],
      fieldCP: ['', Validators.required],
      fieldLatitud: ['', Validators.required],
      fieldLongitud: ['', Validators.required],
    });

    this.entidadesService.getEntidades().subscribe(
      res => {
        this.entidades = res.body;
      }, err => {

      }
    );

    this.updateCentro.subscribe(res => {
      this.refrescaCentro(res);
      this.ngxService.stop();
    });
  }

  refrescaCentro(centro: CentroVO) {
    this.centro = centro;
    this.evcForm.get('fieldFolio').patchValue(centro.folio);
    this.evcForm.get('fieldNombre').patchValue(centro.nombre);
    this.evcForm.get('fieldEntidad').patchValue(centro.idEntidad);
    this.evcForm.get('fieldColonia').patchValue(centro.colonia);
    this.evcForm.get('fieldCalle').patchValue(centro.calle);
    this.evcForm.get('fieldNoExterior').patchValue(centro.noExterior);
    this.evcForm.get('fieldNoInterior').patchValue(centro.noInterior);
    this.evcForm.get('fieldCP').patchValue(centro.cp);
    this.evcForm.get('fieldLatitud').patchValue(centro.latitude);
    this.evcForm.get('fieldLongitud').patchValue(centro.longitude);

  }

  guardaCentro() {
    this.submitedEvc = true;
    if (this.evcForm.invalid) {
      return;
    } else {
      this.ngxService.start();

      this.centro.folio = this.evcForm.get('fieldFolio').value;
      this.centro.nombre = this.evcForm.get('fieldNombre').value;
      this.centro.idEntidad = this.evcForm.get('fieldEntidad').value;
      this.centro.colonia = this.evcForm.get('fieldColonia').value;
      this.centro.calle = this.evcForm.get('fieldCalle').value;
      this.centro.noExterior = this.evcForm.get('fieldNoExterior').value;
      this.centro.noInterior = this.evcForm.get('fieldNoInterior').value;
      this.centro.cp = this.evcForm.get('fieldCP').value;
      this.centro.latitude = this.evcForm.get('fieldLatitud').value;
      this.centro.longitude = this.evcForm.get('fieldLongitud').value;

      this.closeDialog.nativeElement.click();

      this.updateEvent.emit(this.centro);
    }
  }

  get f() { return this.evcForm.controls; }

}
