export class TutorVO {
  idUsuario: number;
  observaciones: string;
  celular: string;
  postulacionesActivas: number;
  postulacionesProcesadas: number;
  totalBecarios: number;
  totalCentros: number;
  totalPlanes: number;
  noSocio: number;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  correo: string;
  idEmpresa: number;
  user_modifica: string;
  ultima_modificacion: Date;
  idEstatus: number;
}
