import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { PlanesService } from 'src/app/services/planes/planes.service';
import { PlanResumen } from 'src/app/model/PlanResumen';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Buscador } from 'src/app/utils/buscador';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Plan } from 'src/app/model/Plan';

@Component({
  selector: 'app-tabla-planes',
  templateUrl: './tabla-planes.component.html',
  styleUrls: ['./tabla-planes.component.scss']
})
export class TablaPlanesComponent implements OnInit {

  @ViewChild('term') term: ElementRef;
  @ViewChild('numRegistrosElem') numRegistrosElem: ElementRef;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;

  @Input() private idEmpresa: number;
  @Input() private updateTable: EventEmitter<boolean>;
  @Output() private verEvent = new EventEmitter<PlanResumen>();
  @Output() private editarEvent = new EventEmitter<PlanResumen>();
  @Output() private eliminarEvent = new EventEmitter<PlanResumen>();


  planes: Array<{ [key: string]: any }>;
  planesO: Array<{ [key: string]: any }>;
  numRegistros = 10;
  idPaginator: string;
  buscador = new Buscador();
  plan: Plan;

  constructor(private planService: PlanesService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.ngxService.start();
    this.idPaginator = 'idPager';
    this.planes = new Array();
    this.planesO = new Array();

    this.actualizaTabla();
    this.updateTable.subscribe(res => {
      this.actualizaTabla();
    });

  }

  actualizaTabla() {
    this.planService.getPlanesListado(this.idEmpresa).subscribe(
      res => {
        this.planes = res.body;
        this.planesO = res.body;
        this.ngxService.stop();

      }, error => {
        console.log(error);
        this.ngxService.stop();

      }
    );
  }

  showPlan(plan) {
    this.verEvent.emit(plan);
  }

  editarPlan(plan) {
    this.editarEvent.emit(plan);
  }

  updateNumRegistros() {
    this.numRegistros = this.numRegistrosElem.nativeElement.value;
  }

  confirmaEliminar(plan) {
    this.eliminarEvent.emit(plan);
  }

  abrirNuevo() {
    const planR = new PlanResumen();
    planR.planObj = new Plan();
    this.editarEvent.emit(planR);
  }

  descargaArchivo() {
    this.ngxService.start();
    this.planService.downloadArchivo(this.idEmpresa, 4).subscribe(
      res => {
        window.open(res.body['urlInicial'], '_blank');
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
      }
    );
  }

  /**
   * Filtra de acuerdo a los parámetros recibidos
   */
  filtra() {
    const term = this.term.nativeElement.value;
    this.planes = this.buscador.filter(this.planesO, term);
  }

}
