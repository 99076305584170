import { User } from './../../../model/user';
import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Buscador } from 'src/app/utils/buscador';
import { PendienteProcesarVO } from 'src/app/model/PendienteProcesarVO';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProcesarService } from 'src/app/services/procesar-jcf/procesar.service';
import { TestService } from 'src/app/services/test.service';

@Component({
  selector: 'app-tabla-procesar',
  templateUrl: './tabla-procesar.component.html',
  styleUrls: ['./tabla-procesar.component.sass']
})
export class TablaProcesarComponent implements OnInit {

  @ViewChild('term') term: ElementRef;
  @ViewChild('numRegistrosElem') numRegistrosElem: ElementRef;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  @ViewChild('confirmaProcesadoSwal') private confirmaProcesadoSwal: SwalComponent;
  @ViewChild('cambioOkSwal') private cambioOkSwal: SwalComponent;

  @Input() private idEmpresa: number;
  @Input() private user: User;

  pendientes: Array<{ [key: string]: any }>;
  pendientesO: Array<{ [key: string]: any }>;
  numRegistros = 10;
  idPaginator: string;
  buscador = new Buscador();
  pendiente: PendienteProcesarVO;
  subscription: any;

  constructor(private ngxService: NgxUiLoaderService,
              private procesarService: ProcesarService,
              private testService: TestService) { }

  ngOnInit() {
    this.ngxService.start();
    this.idPaginator = 'idPager';
    this.pendientes = new Array();
    this.pendientesO = new Array();

    this.actualizaTabla(this.user.idEmpresa);

    this.subscription = this.testService.subject.subscribe((data: any) => {
      this.user = data;
      this.actualizaTabla(this.user.idEmpresa);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  confirmar(pendiente: PendienteProcesarVO) {
    this.pendiente = pendiente;
    this.confirmaProcesadoSwal.show();
  }

  actualizaTabla(idEmpresa: number) {
    this.ngxService.start();
    this.procesarService.getListado(idEmpresa).subscribe(
      res => {
        this.pendientes = res.body;
        this.pendientesO = res.body;
        this.ngxService.stop();
      }, error => {
        this.ngxService.stop();
        this.errorSwal.show();
      }
    );
  }

  confirmarProcesado() {
    this.ngxService.start();
    this.procesarService.cambiaEstatus(this.pendiente.idAlerta, this.user.username).subscribe(
      res => {
        this.procesarService.cambioPendientes.emit(true);
        this.cambioOkSwal.show();
        this.actualizaTabla(this.user.idEmpresa);
      }, err => {
        this.ngxService.stop();
        this.errorSwal.show();
      }
    );

  }

  updateNumRegistros() {
    this.numRegistros = this.numRegistrosElem.nativeElement.value;
  }

  descargaArchivo() {
    this.ngxService.start();
    this.procesarService.descargaArchivo(this.idEmpresa).subscribe(
      res => {
        window.open(res.body['urlInicial'], '_blank');
        this.ngxService.stop();
      },
      err => {
        this.ngxService.stop();
      }
    );
  }
}
