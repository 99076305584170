import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  public subject = new Subject<any>();

  constructor() {}

  setData(data) {  
    this.subject.next(data);
    }

}
