import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MotivoDeclinacion } from 'src/app/model/MotivoDeclinacion';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Declinacion } from 'src/app/model/Declinacion';
import { DeclinarService } from 'src/app/services/postulantes/declinar.service';

@Component({
  selector: 'app-ficha-declinacion-becario',
  templateUrl: './ficha-declinacion-becario.component.html',
  styleUrls: ['./ficha-declinacion-becario.component.scss']
})
export class FichaDeclinacionBecarioComponent implements OnInit {

  @ViewChild('closeBajaDialog') closeBajaDialog: ElementRef;

  @Output() bajaEvent = new EventEmitter();

  motivos: MotivoDeclinacion[];
  bajaForm: FormGroup;
  submitedBaja = false;
  baja: Declinacion;

  constructor(private declinarService: DeclinarService,
    private formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.bajaForm = this.formBuilder.group({
      fieldMotivo: ['', Validators.required],
      fieldComentarios: ['', Validators.required],
    });
    this.declinarService.getMotivosDeclinacion(false, false).subscribe(
      res => {
        this.motivos = res.body;
      }, error => {

      }
    );
  }

  bajaBecario() {
    this.submitedBaja = true;
    if (this.bajaForm.invalid) {
      return;
    } else {
      this.baja = new Declinacion();
      this.baja.idMotivo = this.bajaForm.get('fieldMotivo').value;
      this.baja.comentarios = this.bajaForm.get('fieldComentarios').value;
      this.cierraModalBaja();
      this.bajaEvent.emit(this.baja);
    }
  }

  cierraModalBaja() {
    this.submitedBaja = false;
    this.bajaForm.get('fieldMotivo').setValue('');
    this.bajaForm.get('fieldComentarios').setValue('');
    this.closeBajaDialog.nativeElement.click();
  }

  get f() { return this.bajaForm.controls; }

}
