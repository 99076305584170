import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BecarioAsistencia } from 'src/app/model/BecarioAsistencia';
import { Parser } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class BecarioService {

  apiURL = `${environment.apiURL}user/becario/xestatus`;
  apiURLDownload = `${environment.apiURL}descargascsv`;
  apiURLAsistencia = `${environment.apiURL}user/becario/asistencias`;
  apiURLJustifica = `${environment.apiURL}user/becario/asistencias/actualiza`;

  constructor(private http: HttpClient) { }

  getBecarios(idEmpresa: number) {
    let estatus = '[145]';

    const data = { idEmpresa, idEstatus: estatus };
    return this.http.post<any[]>(this.apiURL, data, { observe: 'response' });
  }

  /**
     * Descarga el archivo de becarios
     * @param idEmpresa identificador de la empresa
     * @param tipo tipo de archivo a descargar
     */
  downloadArchivo(idEmpresa: number, tipo: number) {
    const data = {
      tipo, idEmpresa
    };
    return this.http.post(this.apiURLDownload, data, { observe: 'response' });
  }

  /**
     * Obtiene las asistencias del becario
     * @param idUsuario identificador del usuario
     * @param idMes número del mes a consultar
     */
  consultaAsistencias(idUsuario: number, idMes: number, anio: number) {
    const data = {
      idUsuario, mes: idMes, anio

    };
    return this.http.post<BecarioAsistencia[]>(this.apiURLAsistencia, data, { observe: 'response' });
  }

  /**
     * Registra la incidencia
     * @param idAsistencia identificador del usuario
     * @param observaciones observaciones de la incidencia
     * @param userModifica usuario que realiza la modificacion
     * @param opcion nuevo estado de la asistencia (1: asistencia, 2: falta, 3: retardo, 4: justificado, 5: no laboral)
     */
    registroIncidencia(idAsistencia: number, observaciones: string, userModifica: string, opcion: number) {
      const data = {
        idAsistencia, observacion: observaciones, user_modifica: userModifica, opcion
      };
      return this.http.post(this.apiURLJustifica, data, { observe: 'response' });
    }

}
