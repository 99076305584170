import { AccionesService } from './../../../services/postulantes/acciones.service';
import { Component, ElementRef, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Becario } from 'src/app/model/Becario';
import { Generales } from 'src/app/model/generales';
import { User } from 'src/app/model/user';
import { GeneralesService } from 'src/app/services/dashboard/generales.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PostulantesService } from 'src/app/services/postulantes/postulantes.service';
import { ProcesarService } from 'src/app/services/procesar-jcf/procesar.service';
import { TestService } from 'src/app/services/test.service';
import { environment } from 'src/environments/environment';
import * as AWS from 'aws-sdk';

declare var TextDecoder;

@Component({
  selector: 'app-postulantes-listado',
  templateUrl: './postulantes-listado.component.html',
  styleUrls: ['./postulantes-listado.component.scss']
})
export class PostulantesListadoComponent implements OnInit {

  @ViewChild('asignaTutorSwal') private asignaTutorSwal: SwalComponent;
  @ViewChild('okAsignaTutorSwal') private okAsignaTutorSwal: SwalComponent;
  @ViewChild('okDeclinacionSwal') private okDeclinacionSwal: SwalComponent;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;

  // Tabs
  postulacionesClass = 'selected active';
  postContentClass = 'show active';
  citasClass = '';
  citasContentClass = '';
  historicoClass = '';
  historicoContentClass = '';
  subscription;
  params = {
    Bucket: environment.bucket,
    Key: 'layout-jovenes/postulacion_tmp.json'
  }

  changePostulantes: EventEmitter<boolean> = new EventEmitter();
  changeCitas: EventEmitter<boolean> = new EventEmitter();

  postulante: Becario;
  postulantesList = new Array();
  citasList = new Array();
  user: User;
  becario = new Becario();
  generales = new Generales();

  constructor(private localstorageService: LocalStorageService,
    private generalesService: GeneralesService,
    private accionesService: AccionesService,
    private procesarService: ProcesarService,
    private postulantesService: PostulantesService,
    private ngxService: NgxUiLoaderService,
    private testService:TestService) { }

  ngOnInit() {
    AWS.config.credentials = new AWS.Credentials(environment.awsConfig);

    this.ngxService.start();
    this.user = this.localstorageService.getUser();
    this.getGenerales();
    this.getListadoPostulantes(this.user.idEmpresa);
    this.subscription = this.testService.subject.subscribe((data: any) => {
    this.ngxService.start();
      this.user = data;
      this.getGenerales();
      this.getListadoPostulantes(this.user.idEmpresa);
      error => {
        this.errorSwal.show();
        this.ngxService.stop();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Muestra la ficha del postulante
   *
   * @param postulante
   */
  showFicha(postulante) {
    this.becario = postulante;
  }

  aceptaPostulante($event) {
    this.becario = $event;
    this.asignaTutorSwal.show();
  }

  rechazaPostulante($event) {
    this.becario = $event;
  }

  asignaTutor($event) {
    this.ngxService.start();

    const declinacion = $event;
    this.accionesService.aceptaPostulante(this.becario.idBecario, this.becario.idTutor, this.becario.idEstatus, this.user.username + '')
      .subscribe(res => {
        this.getGenerales();
        this.getListadoPostulantes(this.user.idEmpresa);
        this.procesarService.cambioPendientes.emit(true);
        this.okAsignaTutorSwal.show();
        this.ngxService.stop();
      }, error => {
        this.errorSwal.show();
        this.ngxService.stop();
      });
  }

  declinaPostulacion($event) {
    this.ngxService.start();
    const declinacion = $event;
    declinacion.folio = this.becario.folio;
    declinacion.usuarioModifica = this.user.username + '';
    this.accionesService.declinar(declinacion).subscribe(
      res => {
        this.getGenerales();
        this.getListadoPostulantes(this.user.idEmpresa);
        this.procesarService.cambioPendientes.emit(true);
        this.okDeclinacionSwal.show();
        this.ngxService.stop();
      }, error => {
        this.errorSwal.show();
        this.ngxService.stop();
      }
    );
  }

  getGenerales() {

      this.generalesService.getGenerales(this.user.idEmpresa, 1).subscribe(
        res => {
          this.generales = res.body;
          this.changePostulantes.emit(true);
          this.changeCitas.emit(true);
        },
        error => {
          this.errorSwal.show();
        }
      );
  }

  showPostulaciones() {
    this.postulacionesClass = 'selected active';
    this.postContentClass = 'show active';
    this.citasClass = '';
    this.citasContentClass = '';
    this.historicoClass = '';
    this.historicoContentClass = '';
  }

  showCitas() {
    this.postulacionesClass = '';
    this.postContentClass = '';
    this.citasClass = 'selected active';
    this.citasContentClass = 'show active';
    this.historicoClass = '';
    this.historicoContentClass = '';
  }

  showHistorico() {
    this.postulacionesClass = '';
    this.postContentClass = '';
    this.citasClass = '';
    this.citasContentClass = '';
    this.historicoClass = 'selected active';
    this.historicoContentClass = 'show active';
  }

  readS3File() {
    let s3 = new AWS.S3();
    let tmpStr: string;

    s3.getObject(this.params, (err, data) => {
      if(err) {
        console.log(err);
        this.ngxService.stop();
      } else {
        tmpStr = new TextDecoder('utf-8').decode(data.Body);
        this.createPostulantes(tmpStr);
      }
    });
  }

    getListadoPostulantes(idEmpresa: number) {
      this.postulantesService.getPostulantes(idEmpresa).subscribe(
        res => {
          this.readS3File();
        }, error => {
          this.errorSwal.show();
          this.ngxService.stop();
        }
      );
    }

  createPostulantes(postulantesStr: string) {

    let json = JSON.parse(postulantesStr);
    this.postulantesList = new Array();
    this.citasList = new Array();

    json.forEach(elem => {
      this.postulante = new Becario();

      this.postulante.idBecario = elem.idUsuario;
      this.postulante.folio = elem.folio;
      this.postulante.nombre = elem.nombre;
      this.postulante.aPaterno = elem.apellidoPaterno;
      this.postulante.aMaterno = elem.apellidoMaterno;
      this.postulante.curp = elem.curp;
      this.postulante.correo = elem.correo;
      this.postulante.entidad = elem.entidadBean['descripcion'];
      this.postulante.direccion = elem.direccion;
      this.postulante.calle = elem.calle;
      this.postulante.numExt = elem.noExterior;
      this.postulante.numInt = elem.noInterior;
      this.postulante.colonia = elem.colonia;
      this.postulante.municipio = elem.municipio;
      this.postulante.cp = elem.cp;
      this.postulante.telefono1 = elem.telefono1;
      this.postulante.nombreContacto = elem.nombreContacto;
      this.postulante.telefono2 = elem.telefono2;
      this.postulante.nombreCompleto = (elem.nombreCompleto) ? elem.nombreCompleto :
        elem.nombre + ' ' + elem.apellidoPaterno + ' ' + elem.apellidoMaterno;
      this.postulante.centro = elem.centroBean['nombre'];
      this.postulante.idCentro = elem.centroBean['idCentro'];
      this.postulante.entidadCentro = elem.centroBean['entidadBean'].descripcion;
      this.postulante.plan = elem.planCapacitacionBean['capacitar'];
      this.postulante.tutor = elem.tutoresBean['nombre'];
      this.postulante.idTutor = elem.tutoresBean['idUsuario'];
      this.postulante.fechaIngreso = elem.postulacioBean['fechaInicioCap'];
      this.postulante.estatus = elem.postulacioBean['estatusPostulacionBean'].descripcion;
      this.postulante.idEstatus = elem.postulacioBean['estatusPostulacionBean'].idEstatus;
      this.postulante.empresa = elem.empresaBean['nombre'];
      this.postulante.ultimaActualizacion = elem.postulacioBean['ultima_modificacion'];
      this.postulante.alerta = elem.alerta;

      if(this.postulante.idEstatus == 2 || this.postulante.idEstatus == 3) {
        this.postulantesList.push(this.postulante);
      } else {
        this.citasList.push(this.postulante);
      }
    });
    this.ngxService.stop();
  }
}
