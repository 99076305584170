import { Plan } from './Plan';

export class PlanResumen {
  idPlan: number;
  capacitar: string;
  numPostulacionesActivas: number;
  numPostulacionesProcesadas: number;
  numBecarios: number;
  numCentros: number;
  numTutores: number;
  numEntidades: number;
  planObj: Plan;  
}
