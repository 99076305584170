import { Buscador } from './../../../utils/buscador';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Becario } from 'src/app/model/Becario';
import { PostulantesService } from 'src/app/services/postulantes/postulantes.service';

@Component({
  selector: 'app-tabla-postulaciones',
  templateUrl: './tabla-postulaciones.component.html',
  styleUrls: ['./tabla-postulaciones.component.scss']
})
export class TablaPostulacionesComponent implements OnInit {

  @Input() private tipo: number;
  @Input() private postulantes: Array<{ [key: string]: any }>;
  @Input() private postulantesO: Array<{ [key: string]: any }>;
  @Input() private idEmpresa: number;
  @Input() private updateTable: EventEmitter<boolean>;

  @Output() private fichaEvent = new EventEmitter<Becario>();
  @Output() private aceptaEvent = new EventEmitter<Becario>();
  @Output() private rechazaEvent = new EventEmitter<Becario>();

  @ViewChild('term') term: ElementRef;
  @ViewChild('numRegistrosElem') numRegistrosElem: ElementRef;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;

  postulante: Becario;
  idPaginator: string;
  muestraAceptar: boolean;
  numRegistros = 10;
  buscador = new Buscador();

  constructor(private postulantesService: PostulantesService,
    private ngxService: NgxUiLoaderService ) { }

  ngOnInit() {
    this.idPaginator = 'id' + this.tipo;
    this.postulantes = new Array();
    this.postulantesO = new Array();
    this.postulantesO = this.postulantes;
    if (this.tipo == 1) {
      this.muestraAceptar = false;
    } else if (this.tipo == 2) {
      this.muestraAceptar = true;
    }
  }

  showFicha(pos: Becario) {
    this.fichaEvent.emit(pos);
  }

  aceptaPostulante(pos: Becario) {
    this.aceptaEvent.emit(pos);
  }

  rechazaPostulante(pos: Becario) {
    this.rechazaEvent.emit(pos);
  }

  updateNumRegistros() {
    this.numRegistros = this.numRegistrosElem.nativeElement.value;
  }

  descargaArchivo() {
    this.ngxService.start();

    const type = (this.tipo == 1) ? 8 : 7;
    this.postulantesService.downloadArchivo(this.idEmpresa, type).subscribe(
      res => {
        window.open(res.body['urlInicial'], '_blank');
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
      }
    );
  }

  /**
   * Filtra de acuerdo a los parámetros recibidos
   */
  filtra() {
    const term = this.term.nativeElement.value;
    this.postulantes = this.buscador.filter(this.postulantesO, term);
  }

}
