import { Component, OnInit, OnChanges } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AppComponent } from 'src/app/app.component';
import { User } from 'src/app/model/user';
import { Empresa } from 'src/app/model/Empresa';
import { UsuarioComponent } from 'src/app/components/usuario/usuario.component';
import { EmpresasService } from './../../services/empresas.service';
import { ProcesarService } from 'src/app/services/procesar-jcf/procesar.service';
import { MatDialog } from '@angular/material/dialog';
import { TestService } from 'src/app/services/test.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User;
  empresas: Empresa[];
  conteoPendientes: number = 0;
  postulacionPendiente: number = 0;
  pendientesJCF: number = 0;
  subscription: any;

  constructor(private appComponent: AppComponent,
              private localstorageService: LocalStorageService,
              private procesarService: ProcesarService,
              private empresasService: EmpresasService,
              private testService: TestService,
              private dialog: MatDialog) { }


  ngOnInit() {
    this.user = this.localstorageService.getUser();

    this.empresasService.getEmpresas().subscribe(
      res => {
        this.empresas = res.body;
      }, err => {
        console.log('Error al consultar las empresas');
    });

    this.actualizaContador();

    this.subscription = this.testService.subject.subscribe((data:any) => {
      this.user = data;
      this.actualizaContador();
    });

    this.procesarService.cambioPendientes.subscribe(
      value => {
        this.actualizaContador();
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  actualizaContador() {
    this.procesarService.conteoPendientes(this.user.idEmpresa).subscribe(
      res => {
        this.postulacionPendiente = res.body['postulacionPendiente'];
        this.pendientesJCF = res.body['pendientesJCF'];
        this.conteoPendientes = this.postulacionPendiente + this.pendientesJCF;
      }, err => { }
    );
  }

  openUserInfo() {
    let dialogRef = this.dialog.open(UsuarioComponent, {
      autoFocus: false,
      panelClass: 'modal',
      id: 'usuarioModal',
      maxWidth: '100vw'
    });

    let instance = dialogRef.componentInstance;
    instance.empresas = this.empresas;
  }

  toggleMenu(event) {
    this.appComponent.toggle = !this.appComponent.toggle;
    this.appComponent.setWidth(window.innerWidth, this.appComponent.toggle);
  }
}
