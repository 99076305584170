import { Injectable} from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { UserIdleService } from 'angular-user-idle';
import { LoginService } from 'src/app/services/login.service';



@Injectable({
  providedIn: 'root'
 })

export class AuthGuardService implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private bnIdle: UserIdleService,private loginService: LoginService) {    

    this.ngOnInit();
    
  }     
 
  canActivate(): Promise<boolean> {    
    return new Promise(resolve => {
      if (this.auth.isLoggedIn()) {                
        resolve(true);
      } else {
        this.router.navigateByUrl('/login');
        resolve(false);
      }
    });
  }

  ngOnInit() {    

    this.bnIdle.startWatching();

    this.bnIdle.onTimerStart().subscribe(count=> {         
      var eventList= ['click', 'mouseover','keydown','DOMMouseScroll','mousewheel','mousedown','touchstart','touchmove','scroll','keyup'];
      for(let event of eventList) {
      document.body.addEventListener(event, () =>this.bnIdle.resetTimer());
      }      
      this.bnIdle.onTimeout().subscribe(() => {  
                                    
          if(count ===1800){                          
            this.loginService.conecta(localStorage.getItem('id') ,0).subscribe(
              res3=>{                     
                  this.router.navigateByUrl('/login');  
                  localStorage.clear();    
                }, error => {
                  console.log("ERROR");
                }
            )}
       
        });         
  });  
}




  

}

