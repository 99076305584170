import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { TutorVO } from 'src/app/model/TutorVO';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-evc-tutores',
  templateUrl: './evc-tutores.component.html',
  styleUrls: ['./evc-tutores.component.scss']
})
export class EvcTutoresComponent implements OnInit {

  @Input() editar: boolean;
  @Input() updateTutor: EventEmitter<TutorVO>;

  @Output() updateEvent = new EventEmitter();

  @ViewChild('closeDialog') closeDialog: ElementRef;

  folioMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  telefonoMask = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

  tutor: TutorVO;

  evcForm: FormGroup;
  submitedEvc = false;

  constructor(private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.ngxService.start();
    this.tutor = new TutorVO();

    this.evcForm = this.formBuilder.group({
      fieldNombre: ['', Validators.required],
      fieldAPaterno: ['', Validators.required],
      fieldAMaterno: ['', Validators.required],
      fieldCorreo: ['', Validators.required],
      fieldTelefono: ['', Validators.required],
      fieldNoSocio: ['', Validators.required],
      fieldDescripcion: ['', Validators.required],
    });

    this.updateTutor.subscribe(res => {
      this.refrescaTutor(res);
      this.ngxService.stop();
    });
  }

  refrescaTutor(tutor: TutorVO) {
    this.tutor = tutor;
    this.evcForm.get('fieldNombre').patchValue(tutor.nombre);
    this.evcForm.get('fieldAPaterno').patchValue(tutor.apellidoPaterno);
    this.evcForm.get('fieldAMaterno').patchValue(tutor.apellidoMaterno);
    this.evcForm.get('fieldCorreo').patchValue(tutor.correo);
    this.evcForm.get('fieldTelefono').patchValue(tutor.celular);
    this.evcForm.get('fieldNoSocio').patchValue(tutor.noSocio);
    this.evcForm.get('fieldDescripcion').patchValue(tutor.observaciones);

  }

  guardaTutor() {

    this.submitedEvc = true;
    if (this.evcForm.invalid) {
      console.log('Inválido');
      return;
    } else {
      this.ngxService.start();
      this.tutor.nombre = this.evcForm.get('fieldNombre').value;
      this.tutor.apellidoPaterno = this.evcForm.get('fieldAPaterno').value;
      this.tutor.apellidoMaterno = this.evcForm.get('fieldAMaterno').value;
      this.tutor.correo = this.evcForm.get('fieldCorreo').value;
      this.tutor.celular = this.evcForm.get('fieldTelefono').value;
      this.tutor.noSocio = this.evcForm.get('fieldNoSocio').value;
      this.tutor.observaciones = this.evcForm.get('fieldDescripcion').value;

      this.closeDialog.nativeElement.click();

      this.updateEvent.emit(this.tutor);
    }
  }

  get f() { return this.evcForm.controls; }

}
