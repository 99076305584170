import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CargaMasiva } from '../model/CargaMasiva';

@Injectable({
  providedIn: 'root'
})
export class ListaCargaMasivaService {

  apiURL = `${environment.apiURL}cargasmasivas/listado`;

  constructor(private http: HttpClient) { }

  getListaCargaMasiva(idEmpresa: number, idTipo: number) {
    const data = {
      idEmpresa, idTipo
    };

    return this.http.post<CargaMasiva[]>(this.apiURL, data, { observe: 'response' });
  }

}
