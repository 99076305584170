import { Component, HostListener, OnInit } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { User } from './model/user';
import { LocalStorageService } from './services/local-storage.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {  
  title = 'jcf-portal-admin';
  url = '/login';
  toggle = false;
  widthSize: string;
  user: User;
  contadorAlertas: number;   
  showScroll: boolean;
    showScrollHeight = 300;
    hideScrollHeight = 10;


  constructor(private router: Router,
              private localstorageService: LocalStorageService) {                              

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.url = event.url;
        this.user = this.localstorageService.getUser();
        this.setWidth(window.innerWidth, this.toggle);
      }
    });   
    
  }

  setWidth(windowSize: number, toggle: boolean) {
    if (this.url !== '/login' && this.url !== '/') {
      if (toggle) {
        this.widthSize = (windowSize - 130) + 'px';
      } else {
        this.widthSize = (windowSize - 270) + 'px';
      }
    } else {
      this.widthSize = windowSize + 'px';
    }
  }


 
  
}
