import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CentroVO } from 'src/app/model/CentroVO';
import { Entidad } from 'src/app/model/Entidad';
import { PlanCentroVO } from 'src/app/model/PlanCentroVO';
import { PlanResumen } from 'src/app/model/PlanResumen';
import { User } from 'src/app/model/user';
import { EntidadesService } from 'src/app/services/catalogos/entidades.service';
import { PlanCentroService } from 'src/app/services/plancentro/plan-centro.service';
import { PlanesService } from 'src/app/services/planes/planes.service';
import { LocalStorageService } from './../../../../services/local-storage.service';
import { TutorVO } from 'src/app/model/TutorVO';
import { TutorService } from 'src/app/services/tutores/tutor.service';

@Component({
  selector: 'app-evc-plancentro',
  templateUrl: './evc-plancentro.component.html',
  styleUrls: ['./evc-plancentro.component.sass']
})
export class EvcPlancentroComponent implements OnInit {

  espaciosMask = [/\d/, /\d/];
  horarioMask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

  @Input() editar: boolean;
  @Input() updatePlanCentro: EventEmitter<PlanCentroVO>;
  @Output() updateEvent = new EventEmitter();

  @ViewChild('closeDialog') closeDialog: ElementRef;

  planCentro: PlanCentroVO;
  user: User;

  control = new FormControl();

  entidades: Entidad[];
  centros: CentroVO[];
  centrosO: CentroVO[];
  planes: PlanResumen[];
  planesO: PlanResumen[];
  tutores: TutorVO[];
  tutoresO: TutorVO[];

  evcForm: FormGroup;
  submitedEvc = false;
  isLoading = false;

  constructor(private formBuilder: FormBuilder,
    private entidadesService: EntidadesService,
    private planCentroService: PlanCentroService,
    private planService: PlanesService,
    private tutorService: TutorService,
    private ngxService: NgxUiLoaderService,
    private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.ngxService.start();
    this.user = this.localStorageService.getUser();
    this.planCentro = new PlanCentroVO();
    this.centros = new Array();
    this.centrosO = new Array();

    this.evcForm = this.formBuilder.group({
      fieldEntidad: ['', Validators.required],
      fieldCentro: ['', Validators.required],
      fieldPlan: ['', Validators.required],
      fieldTutor: ['', Validators.required],
      fieldOfertados: ['', Validators.required],
      fieldLunes: [''],
      fieldMartes: [''],
      fieldMiercoles: [''],
      fieldJueves: [''],
      fieldViernes: [''],
      fieldSabado: [''],
      fieldDomingo: [''],
      fieldHoraEntrada: ['', Validators.required],
      fieldHoraSalida: ['', Validators.required],
    });

    this.entidadesService.getEntidades().subscribe(
      res => {
        this.entidades = res.body;
      }, err => {

      }
    );

    this.planService.getPlanesListado(this.user.idEmpresa).subscribe(
      res => {
        this.planes = res.body;
        this.planesO = res.body;
      }, err => {

      }
    );

    this.tutorService.getTutoresListado(this.user.idEmpresa).subscribe(
      res => {
        this.tutores = res.body;
        this.tutoresO = res.body;
      }, err => {

      }
    );

    this.evcForm.get('fieldCentro').valueChanges.subscribe(
      value => {
        this.centros = this.filtrar(this.centrosO, value, 'centro');
      }
    );

    this.evcForm.get('fieldPlan').valueChanges.subscribe(
      value => {
        this.planes = this.filtrar(this.planesO, value, 'plan');
      }
    );

    this.evcForm.get('fieldTutor').valueChanges.subscribe(
      value => {
        this.tutores = this.filtrar(this.tutoresO, value, 'tutor');
      }
    );

    this.evcForm.get('fieldHoraEntrada').valueChanges.subscribe(
      value => {
        if (value && value.length >= 2) {
          const val = +value.substring(0, 2);
          if (val > 23) {
            this.evcForm.get('fieldHoraEntrada').patchValue(value.substring(0, 1));
          }
        }
      }
    );

    this.evcForm.get('fieldHoraSalida').valueChanges.subscribe(
      value => {
        if (value && value.length >= 2) {
          const val = +value.substring(0, 2);
          if (val > 23) {
            this.evcForm.get('fieldHoraSalida').patchValue(value.substring(0, 1));
          }
        }
      }
    );

    this.updatePlanCentro.subscribe(res => {
      this.refrescaPlanCentro(res);
      this.ngxService.stop();
    });
  }

  refrescaPlanCentro(pc: PlanCentroVO) {
    this.planCentro = pc;

    this.buscaCentros(pc.idEntidad, 0);
    const dias = pc.dias ? pc.dias.split('|') : [];
    const horario = pc.horario ? pc.horario.split('-') : [];
    this.evcForm.get('fieldEntidad').patchValue(pc.idEntidad);
    this.evcForm.get('fieldCentro').patchValue(pc.centro);
    this.evcForm.get('fieldPlan').patchValue(pc.plan);
    this.evcForm.get('fieldTutor').patchValue(pc.tutor);
    this.evcForm.get('fieldOfertados').patchValue(pc.ofertados);
    this.evcForm.get('fieldLunes').patchValue(dias.includes('1') ? 1 : null);
    this.evcForm.get('fieldMartes').patchValue(dias.includes('2') ? 2 : null);
    this.evcForm.get('fieldMiercoles').patchValue(dias.includes('3') ? 3 : null);
    this.evcForm.get('fieldJueves').patchValue(dias.includes('4') ? 4 : null);
    this.evcForm.get('fieldViernes').patchValue(dias.includes('5') ? 5 : null);
    this.evcForm.get('fieldSabado').patchValue(dias.includes('6') ? 6 : null);
    this.evcForm.get('fieldDomingo').patchValue(dias.includes('7') ? 7 : null);
    this.evcForm.get('fieldHoraEntrada').patchValue(this.formatHora(horario[0]));
    this.evcForm.get('fieldHoraSalida').patchValue(this.formatHora(horario[1]));

  }

  guardaPlanCentro() {
    this.submitedEvc = true;
    if (this.evcForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      this.submitedEvc = false;
      this.closeDialog.nativeElement.click();
      this.planCentro.idEntidad = this.evcForm.get('fieldEntidad').value;
      this.planCentro.ofertados = this.evcForm.get('fieldOfertados').value;

      let dias: string = (this.evcForm.get('fieldLunes').value) ? '1' : '';
      dias += (this.evcForm.get('fieldMartes').value) ? (dias.length > 0) ? '|2' : '2' : '';
      dias += (this.evcForm.get('fieldMiercoles').value) ? (dias.length > 0) ? '|3' : '3' : '';
      dias += (this.evcForm.get('fieldJueves').value) ? (dias.length > 0) ? '|4' : '4' : '';
      dias += (this.evcForm.get('fieldViernes').value) ? (dias.length > 0) ? '|5' : '5' : '';
      dias += (this.evcForm.get('fieldSabado').value) ? (dias.length > 0) ? '|6' : '6' : '';
      dias += (this.evcForm.get('fieldDomingo').value) ? (dias.length > 0) ? '|7' : '7' : '';

      this.planCentro.dias = dias;

      const horaEntrada = this.formatHora(this.evcForm.get('fieldHoraEntrada').value);
      const horaSalida = this.formatHora(this.evcForm.get('fieldHoraSalida').value);

      this.planCentro.horario = horaEntrada + '-' + horaSalida;
      // this.ngxService.stop();

      this.updateEvent.emit(this.planCentro);
    }
  }

  buscaCentros(idEntidad, limpia) {

    this.ngxService.start();
    this.planCentroService.getCentrosXEmpresaEntidad(this.user.idEmpresa, idEntidad).subscribe(
      res => {
        if (limpia === 1) {
          this.evcForm.get('fieldCentro').patchValue('');
        }
        this.centros = res.body;
        this.centrosO = res.body;
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
      }
    );
  }

  salioCentro() {
    setTimeout(() => {
      this.evcForm.get('fieldCentro').patchValue(this.planCentro.centro);
    }, 200);
  }

  salioPlan() {
    setTimeout(() => {
      this.evcForm.get('fieldPlan').patchValue(this.planCentro.plan);
    }, 200);
  }

  salioTutor() {
    setTimeout(() => {
      this.evcForm.get('fieldTutor').patchValue(this.planCentro.tutor);
    }, 200);
  }

  displayFn(tipo: string) {

    switch (tipo) {
      case 'centro':
        const centro: CentroVO = this.evcForm.get('fieldCentro').value;
        this.planCentro.idCentro = centro.idCentro;
        this.planCentro.centro = centro.nombre;
        break;
      case 'plan':
        const plan: PlanResumen = this.evcForm.get('fieldPlan').value;
        this.planCentro.idPlan = plan.idPlan;
        this.planCentro.plan = plan.capacitar;
        break;
      case 'tutor':
        const tutor: TutorVO = this.evcForm.get('fieldTutor').value;
        this.planCentro.idTutor = tutor.idUsuario;
        this.planCentro.tutor = tutor.nombre + ' ' + tutor.apellidoPaterno + ' ' + tutor.apellidoMaterno;
        break;
    }

  }

  formatHora(hora: string): string {
    if (hora) {
      const h = hora.split(':');
      const num1 = (h[0].length === 1) ? '0' + h[0] : h[0];
      const num2 = (h[1] && h[1].length === 1) ? '0' + h[1] : (h[1]) ? h[1] : '00';
      return num1 + ':' + num2;
    } else {
      return;
    }
  }

  filtrar(arreglo: any[], valor: string, propiedad: string): any {
    if (typeof valor === 'string') {
      let prop: string;

      if (!arreglo || !valor) {
        return arreglo;
      }

      if (propiedad === 'centro' || propiedad === 'plan') {
        if (propiedad === 'centro') {
          prop = 'nombre';
        } else if (propiedad === 'plan') {
          prop = 'capacitar';
        }
        const filtrados = arreglo.filter(
          item =>
            item[prop]
              .toLowerCase()
              .includes(valor.toLowerCase()) === true
        );

        return filtrados;
      } else if (propiedad === 'tutor') {
        const filtrados = arreglo.filter(
          item =>
            item['nombre']
              .toLowerCase()
              .includes(valor.toLowerCase()) === true ||
            item['apellidoPaterno']
              .toLowerCase()
              .includes(valor.toLowerCase()) === true ||
            item['apellidoMaterno']
              .toLowerCase()
              .includes(valor.toLowerCase()) === true
        );

        return filtrados;
      }
    }
  }

  get f() { return this.evcForm.controls; }

}
