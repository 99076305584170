import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

export class Crypt {

  encrypt(text: string) {
    const ct = CryptoJS.AES.encrypt(text, environment.secret_key);
    return ct.toString();
  }

}
