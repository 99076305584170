import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PlanCentroVO } from 'src/app/model/PlanCentroVO';
import { CentroVO } from 'src/app/model/CentroVO';

@Injectable({
  providedIn: 'root'
})
export class PlanCentroService {

  apiURLListado = `${environment.apiURL}plancentro/listado`;
  apiURLCentros = `${environment.apiURL}centros/entidad`;
  apiURLInsert = `${environment.apiURL}plancentro/actualizacreaplancentro`;
  apiURLDownload = `${environment.apiURL}descargascsv`;

  constructor(private http: HttpClient) { }

  /**
   * Obtiene el listado de plan-centro por empresa
   * @param idEmpresa Id de la empresa a buscar
   */
  getPlanCentroListado(idEmpresa: number) {
    const data = { idEmpresa };
    return this.http.post<PlanCentroVO[]>(this.apiURLListado, data, { observe: 'response' });
  }

  /**
   * Obtiene el listado de centros por empresa y entidad
   * @param idEmpresa Id de la empresa a buscar
   * @param idEntidad Id de la entidad a buscar
   */
  getCentrosXEmpresaEntidad(idEmpresa: number, idEntidad: number) {
    const data = { idEmpresa, idEntidad };
    return this.http.post<CentroVO[]>(this.apiURLCentros, data, { observe: 'response' });
  }

  /**
   * Actualiza o crea un plan - centro
   * @param planCentro Objeto de tipo PlanCentroVO a ser actualizado o creado
   * @param tipo Tipo de acción a realizar [ 1 - Actualizar, 2 - Crear ]
   */
  updatePlanCentro(pc: PlanCentroVO, tipo: number) {

    const horas = pc.horario.split('-');

    const data = {
      idPlanCentro: pc.idPlanCentro,
      idCentro: pc.idCentro,
      idPlan: pc.idPlan,
      noJovenes: pc.ofertados,
      idTutor: pc.idTutor,
      dias: pc.dias,
      horaEntrada: horas[0],
      horaSalida: horas[1],
      user_modifica: pc.user_modifica,
      idTipo: tipo
    };
    return this.http.post<PlanCentroVO>(this.apiURLInsert, data, { observe: 'response' });
  }

  /**
     * Descarga el archivo de plan-centro
     * @param idEmpresa identificador de la empresa
     * @param tipo tipo de archivo a descargar
     */
    downloadArchivo(idEmpresa: number, tipo: number) {
      const data = {
        tipo, idEmpresa
      };
      return this.http.post(this.apiURLDownload, data, { observe: 'response' });
    }

}
