export class User {
  idUsuario: number;
  username: string;
  password: string;
  // email: string;
  nombre: string;
  idPerfil: number;
  correo: string;
  nomEmpresa: string;
  idEmpresa: number;
  idFolio: number;
  idRol: number;
  idConecta:number;
}
