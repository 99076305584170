import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
​
@Injectable({
  providedIn: 'root'
})

export class BorraCache {
    //inicio : number;
    //fin: number;
    //final: number;

    
    constructor(private router: Router) { }    ​
      canActivate(          
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise(async (resolve, reject) => {        
          //this.inicio = Date.now();                              
          localStorage.clear();

          //this.fin = Date.now();          

          //this.final = (this.fin - this.inicio);          

          resolve(true);


        })​
      }
 }


 