import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PlanResumen } from 'src/app/model/PlanResumen';
import { Plan } from 'src/app/model/Plan';

@Injectable({
  providedIn: 'root'
})
export class PlanesService {

  apiURLListado = `${environment.apiURL}planes/listado`;
  apiURLInsert = `${environment.apiURL}planes/modificacion`;
  apiURLDelete = `${environment.apiURL}planes/borrar-plan`;
  apiURLDownload = `${environment.apiURL}descargascsv`;

  constructor(private http: HttpClient) { }

  getPlanesListado(idEmpresa: number) {
    const data = { idEmpresa };
    return this.http.post<PlanResumen[]>(this.apiURLListado, data, { observe: 'response' });
  }

  updatePlan(plan: Plan, tipo: number) {
    const data = { plan, tipo };
    return this.http.post<PlanResumen[]>(this.apiURLInsert, data, { observe: 'response' });
  }

  eliminaPlan(plan: Plan) {
    const data = { idPlan: plan.idPlan };
    return this.http.post<number>(this.apiURLDelete, data, { observe: 'response' });
  }

  /**
     * Descarga el archivo de planes
     * @param idEmpresa identificador de la empresa
     * @param tipo tipo de archivo a descargar
     */
  downloadArchivo(idEmpresa: number, tipo: number) {
    const data = {
      tipo, idEmpresa
    };
    return this.http.post(this.apiURLDownload, data, { observe: 'response' });
  }

}
