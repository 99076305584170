import { Generales } from './../../model/generales';
import { GeneralesService } from './../../services/dashboard/generales.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/model/user';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';
import * as Highcharts from 'highcharts';
window['Highcharts']=Highcharts;
import * as Highcharts2 from 'highcharts/highmaps';
import { MapChart } from 'angular-highcharts';
import { Chart } from 'angular-highcharts';
import mapaMexico from '../../../assets/mapaMexico';
import highcharts3D from 'highcharts/highcharts-3d.src';
import { TestService } from 'src/app/services/test.service';
highcharts3D(Highcharts);
import swal from 'sweetalert2';

import * as $ from 'jquery';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  })

export class DashboardComponent implements OnInit {
  generales = new Generales();
  user: User;

  mapChart: MapChart;
  GraficaMH:any;
  GraficaPostulantes :any;
  GrafHistoricoSem :any;
  GraficaEscoralidad :any;

  GraficaEdad:any;
  OptionsEdad: any;
  messages: any[] = [];
  mapaCDMX : any;
  subscription;


  constructor(private generalesService: GeneralesService,
    private localstorageService: LocalStorageService,
    private ngxService: NgxUiLoaderService,private http: HttpClient,
    private testService: TestService) {
     }




  ngOnInit() {
    this.ngxService.start();
    this.user = this.localstorageService.getUser();
    this.generalesService.getGenerales(this.user.idEmpresa, 1).subscribe(
      res => {
        this.generales = res.body;
        this.mapaMexico();
        this.graficaPostulantes();
        this.graficaMH3d();
        this.grafHistoricoSemanal();
        this.graficaEcoralidad();
        this.graficaEDAD();
        this.ngxService.stop();
      },
      error => {
        this.ngxService.stop();
      }
    );

    this.subscription = this.testService.subject.subscribe((data: any)=>{

      this.user = data;
      this.getCambiaEmpresa(this.user.idEmpresa)
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getCambiaEmpresa(idEmpresa){
    this.generalesService.getGenerales(idEmpresa, 1).subscribe(
      res => {
        this.generales = res.body;
        this.mapaMexico();
        this.graficaPostulantes();
        this.graficaMH3d();
        this.graficaEcoralidad();
        this.graficaEDAD();
        this.grafHistoricoSemanal();
        this.ngxService.stop();
      },
      error => {
        this.ngxService.stop();
      }
    );
  }


  mapaMexico(){
    this.mapaCDMX =   mapaMexico;
    this.generalesService.getDatosPorEstado(this.user.idEmpresa,1).subscribe(
      res => {
        var data = res.body['data'];
        Highcharts2.mapChart('container', {
          chart: {
            map:  this.mapaCDMX //mapaMexico
        },
        title: {
          text: 'Datos Estados'
          },
          subtitle: {
            text: ''
        }, mapNavigation: {
          enabled: true,
            buttonOptions: {
                verticalAlign: 'bottom'
            }
        },
          colorAxis: {
            minColor: '#C0E4DB',
            maxColor: Highcharts2.getOptions().colors[7]
          },
          series: [{
            type: 'map',
            name: 'Becarios',
            data:data,
            color: '#C0E4DB',
            states: {
              hover: {
                  color: '#BADA55'
              }
          },
          dataLabels: {
              enabled: true,
              format: '{point.name}'
          }
          }]
      });
      },
      error => {
        this.ngxService.stop();
      }
    );
  }

  graficaPostulantes(){

    var pendiente =0;
    var p,d,a;
    var declinado = 0;
    var aceptado = 0;
    var data = [];

    if(this.generales.noPendiente>pendiente){
      p = ['PENDIENTES',this.generales.noPendiente];
      data.push(p);
    }
    if(this.generales.noDeclinado>declinado){
      d = ['DECLINADOS',this.generales.noDeclinado];
      data.push(d);
    }

     if(this.generales.noAceptado>aceptado){
      a = ['ACEPTADOS',   this.generales.noAceptado];
      data.push(a);
    }

    if(data.length>0){
        $("#graficasdiv1").show();
        $("#graficasdiv2").show();
        $("#graficasdiv3").show();

        Highcharts.chart('graficaGeneral', {
            chart: {
                type: 'pie',
            },
            title : {
              text: ''
            },
            tooltip : {
              crosshairs: [false, true]
            },
            plotOptions : {
              pie: {
                allowPointSelect: false,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b><br>{point.percentage:.1f}%',
                    distance: -50,
                    style: {
                      fontWeight: 'bold',
                      color: '#FFFFFF'
                     }
                },
                 point: {
                   events: {
                     click:  (event)=> {
                      if(event.point.name==='DECLINADOS'){
                        this.callExternalFunction();
                      }
                     }
                   }
                },
                showInLegend: false
              }
            },
            series : [{
              type: 'pie',
              size: '80%',
              name: 'Cantidad',
              data: data
          }]
        });
    }else{
      $("#graficasdiv1").hide();
      $("#graficasdiv2").hide();
      $("#graficasdiv3").hide();
    }
  }


  graficaEcoralidad(){
    this.generalesService.getDatosPorEscolaridad(this.user.idEmpresa,2).subscribe(
      res => {

        var data = res.body['datosEscolaridad'];
        var json = JSON.parse(res.body['datosEscolaridad']);

        if(json['data'].length>0){
          $("#graficasdiv1").show();
          $("#graficasdiv2").show();
          $("#graficasdiv3").show();

       Highcharts.chart('graficaEscoralidad',{
              chart: {
                type: 'column'
            },
            title: {
                text: 'Escolaridad de aprendices vinculados'
            },
            xAxis:{
              categories: json['categories']
            },
            yAxis: {
              title: {
                  text: ''
              },
              labels: {
                formatter: function () {
                    return Highcharts.numberFormat(this.value,0);
                }
              }
            },
              plotOptions : {
              series: {
                  showInLegend: false
              }
            },
            series: [
              {
                type: 'column',
              name: "Cantidad",
              colorByPoint: true,

              data: json['data']
            }]
       });
      }else{
        $("#graficasdiv1").hide();
        $("#graficasdiv2").hide();
        $("#graficasdiv3").hide();
      }
    },
     error => {
        this.ngxService.stop();
      }

    );

  }


  grafHistoricoSemanal(){

    this.generalesService.getDatosHistorial(this.user.idEmpresa,5).subscribe(
      res => {

        var data = res.body['datosEscolaridad'];
        var json = JSON.parse(res.body['datosEscolaridad']);

        if(json['serie'].length>0){
                $("#graficasdiv1").show();
                $("#graficasdiv2").show();
                $("#graficasdiv3").show();


            Highcharts.chart('GraficoHistoricoSemana',{
                     chart : {
                      type:'area'
                  },
                    title: {
                      text: 'Histórico de vinculación por inicio de capacitación.'
                  },
                  subtitle: {
                      text: ''
                  },
                  xAxis: {
                    labels: {
                      enabled: true
                  },
                  title: {
                    text: 'Semana'
                  },
                      categories: json['categories']
                  },
                  yAxis: {
                    allowDecimals: false,
                    title: {
                        text: 'Becarios'
                    }
                  },
                  plotOptions : {
                  series: {
                    showInLegend: false,
                    tooltip: {
                        headerFormat: 'Semana: {point.x}<br/>',
                        pointFormat: 'Vinculados: {point.y}<br/>'
                    },
                },
              },
                  series: [{
                    type: 'area',
                    colorByPoint: true,
                    data: json['serie'],
                    color: Highcharts.getOptions().colors[0],
                    fillColor: {
                      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                      stops: [
                         [0, Highcharts.getOptions().colors[0]],
                         [1, '#eeeeee']
                      ]
                  },
                  }],
            });


          }else{
            $("#graficasdiv1").hide();
            $("#graficasdiv2").hide();
            $("#graficasdiv3").hide();
          }
        },
      error => {
        this.ngxService.stop();
      }
    );
  }


  graficaMH3d(){

     this.generalesService.getDatosGenero(this.user.idEmpresa,3).subscribe(
      res => {
        var data = res.body['data'];
        if(res.body['data'].length>0){
          $("#graficasdiv1").show();
          $("#graficasdiv2").show();
          $("#graficasdiv3").show();

          Highcharts.chart('graficaHombreMujer',{
                chart : {
                  type:'pie',
                  options3d: {
                    enabled: true,
                    alpha: 40,
                    beta: 0
                  }
              },
              title : {
                  text: 'Mujeres y Hombres'
              },
              tooltip : {
                crosshairs: [false, true]
                //crosshairs: [false, true],
                  //pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              },
              colors: ['#A3DB5A','#32C299'],
              plotOptions : {
                  pie: {
                    innerSize: '25%',
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>:<br> {point.percentage:.1f} %',
                        style: {
                        fontWeight: 'bold',
                        color: 'black',
                        }
                    }
                  }
              },
              series : [{
                  type: 'pie',
                  name: 'Cantidad',
                  data: data
              }]
          });
        }else{
          $("#graficasdiv1").hide();
          $("#graficasdiv2").hide();
          $("#graficasdiv3").hide();
        }
      },
      error => {
        this.ngxService.stop();
      });
  }


  graficaEDAD(){
    this.generalesService.getDatosEdad(this.user.idEmpresa,4).subscribe(
      res => {
        var data = res.body['datosEscolaridad'];
        var json = JSON.parse(res.body['datosEscolaridad']);

        if(json['data'].length>0){
          $("#graficasdiv1").show();
          $("#graficasdiv2").show();
          $("#graficasdiv3").show();

            Highcharts.chart('graficaEdad',{
                    chart: {
                      type: 'column'
                  },
                  title: {
                      text: 'Edad de aprendices vinculados<br>'
                  },
                  yAxis: {
                    title: {
                        text: ''
                    },
                    labels: {
                      formatter: function () {
                          return Highcharts.numberFormat(this.value,0);
                      }
                    }
                  },
                  xAxis:{
                    categories: json['categories']
                  },
                  plotOptions : {
                    series: {
                        showInLegend: false
                    }
                  },
                  tooltip: {
                  headerFormat: 'Edad: {point.x}<br/>',
                  pointFormat: 'Total: {point.y}<br/>'
              },
                  series: [
                    {
                      type: 'column',
                      colorByPoint: true,
                      data: json['data']
                    }
                ]
            });

      }else{
        $("#graficasdiv1").hide();
        $("#graficasdiv2").hide();
        $("#graficasdiv3").hide();
      }
    },
      error => {
        this.ngxService.stop();
      }
    );
  }



    callExternalFunction(){
        swal.fire({
          title: 'MOTIVOS',
          width: '700px',
          heightAuto:true,
          html:
          '<br><br>'+
          '<div class="row">'+
              '<div class="col-12"><div id="grafBajas" style = "text-align: -webkit-center;"></div></div>'+
          '</div>',
          showCloseButton: true
          //showCancelButton: true
        });

     this.generalesService.getDatosMotivosBD(this.user.idEmpresa ,6).subscribe(
      res => {
        var json = JSON.parse(res.body['data1']);

        Highcharts.chart('grafBajas', {
          title: {
              text: ''
          },
          subtitle: {
              text: ''
          },
          plotOptions: {
              series: {
                  dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>    {point.percentage:.1f}%',
                  }
              }
          },
          tooltip: {
            shared: true,
            useHTML:true,
            formatter: function() {
              var html = "";

              html =   '<span style="color:'+ this.point.color +'">CANTIDAD '+this.point.name+': <b>'+this.point.y+'</b></span><br><br>'+
              '<div class="row" style="width: 580px;height: auto;">';
              this.point.data.forEach(element => {
                html += '<div class="col-10 text-left">'+ element.r +'</div><div class="col-2 text-right">'+element.z+'</div>';
              });
              html += '</div>';
              return html;
            },
          },
          series: [
              {
                  type: 'pie',
                  name: "Browsers",
                  colorByPoint: true,
                /*   data: [
                    {color: "#96C299", name: "DECLINADO", y: 381,data:[{z:89,r:"total"},{z:6,r:"totalesas"}]},
                    {color: "#19E527", name: "BAJA", y: 5,data:[{z:89789789,r:"total"},{z:1321321,r:"totalesas"}]}
                  ],   */
                 data: json['data']

              }
          ]
        });
      },
      error => {
        this.ngxService.stop();
      });
  }
}

