import { CentroService } from 'src/app/services/centros/centro.service';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { User } from 'src/app/model/user';
import { CentroVO } from 'src/app/model/CentroVO';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-listado-centros',
  templateUrl: './listado-centros.component.html',
  styleUrls: ['./listado-centros.component.scss']
})
export class ListadoCentrosComponent implements OnInit {

  @ViewChild('centroExistenteSwal') private centroExistenteSwal: SwalComponent;
  @ViewChild('okCreaCentroSwal') private okCreaCentroSwal: SwalComponent;
  @ViewChild('okModificaCentroSwal') private okModificaCentroSwal: SwalComponent;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  @ViewChild('eliminarCentroSwal') private eliminarCentroSwal: SwalComponent;
  @ViewChild('activarCentroSwal') private activarCentroSwal: SwalComponent;
  @ViewChild('okEliminaCentroSwal') private okEliminaCentroSwal: SwalComponent;
  @ViewChild('okActivaCentroSwal') private okActivaCentroSwal: SwalComponent;
  @ViewChild('errorEliminarCentroSwal') private errorEliminarCentroSwal: SwalComponent;

  changeCentro: EventEmitter<CentroVO> = new EventEmitter();
  updateEvent: EventEmitter<boolean> = new EventEmitter();

  user: User;
  centro = new CentroVO();
  editar = false;

  constructor(private localstorageService: LocalStorageService,
    private centroService: CentroService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.user = this.localstorageService.getUser();
  }

  verCentro($event) {
    this.centro = $event;
    this.editar = false;

    this.changeCentro.emit(this.centro);
  }

  editarCentro($event) {
    this.centro = $event;
    this.editar = true;
    this.changeCentro.emit(this.centro);
  }

  confirmaEliminarCentro($event) {
    this.centro = $event;
    if (this.centro.idEstatus === 1) {
      this.eliminarCentroSwal.show();
    } else {
      this.activarCentroSwal.show();
    }
  }

  eliminaCentro(accion: number) {
    this.ngxService.start();
    this.centro.user_modifica = this.user.username;
    this.centroService.eliminaCentro(this.centro, accion).subscribe(
      res => {
        if (!res.body['error']) {
          this.ngxService.stop();

          if (accion === 1) {
            this.okActivaCentroSwal.show();
          } else {
            this.okEliminaCentroSwal.show();
          }
          this.updateEvent.emit(true);
        } else {
          this.ngxService.stop();
          this.errorEliminarCentroSwal.show();
        }
      }, error => {
        this.ngxService.stop();
        this.errorSwal.show();
      }
    );
  }

  updateCentro($event: CentroVO) {
    let tipo: number;
    if (this.editar && $event.idCentro) {
      tipo = 1;
    } else {
      tipo = 2;
    }

    $event.idEmpresa = this.user.idEmpresa;
    $event.user_modifica = this.user.username;

    this.centroService.updateCentro($event, tipo).subscribe(
      res => {                
        if (tipo === 2) {
          if (res.body['code'] == 201){
            this.centroExistenteSwal.show();
          }else{
            this.okCreaCentroSwal.show();
          }          
        } else if (tipo === 1) {
          this.okModificaCentroSwal.show();
        }
        this.ngxService.stop();
        this.updateEvent.emit(true);
      }, error => {
        this.errorSwal.show();
        this.ngxService.stop();
      }
    );

  }

}
