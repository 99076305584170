import { Component, OnInit, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { User } from 'src/app/model/user';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { GeneralesService } from 'src/app/services/dashboard/generales.service';
import { Generales } from 'src/app/model/generales';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Becario } from 'src/app/model/Becario';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AccionesService } from 'src/app/services/postulantes/acciones.service';
import { BecarioAsistencia } from 'src/app/model/BecarioAsistencia';
import { ProcesarService } from 'src/app/services/procesar-jcf/procesar.service';
import { BecarioService } from 'src/app/services/becario/becario.service';
import { TestService } from 'src/app/services/test.service';
import { environment } from 'src/environments/environment';
import * as AWS from 'aws-sdk';

declare var TextDecoder;

@Component({
  selector: 'app-becarios-listado',
  templateUrl: './becarios-listado.component.html',
  styleUrls: ['./becarios-listado.component.scss']
})
export class BecariosListadoComponent implements OnInit {

  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  @ViewChild('okBajaSwal') private okBajaSwal: SwalComponent;

  changeBecarios: EventEmitter<boolean> = new EventEmitter();
  changeBajas: EventEmitter<boolean> = new EventEmitter();

  becariosClass = 'selected active';
  becariosContentClass = 'show active';
  evaluacionesClass = '';
  evaluacionesContentClass = '';
  bajasClass = '';
  bajasContentClass = '';
  user: User;
  generales = new Generales();
  becario = new Becario();
  registro = new BecarioAsistencia();
  becariosList = new Array();
  becariosBajaList = new Array();
  becariosTotal;
  actualiza = false;
  readedjSON: any;
  someStr = '';
  subscription;

  params = {
        Bucket: environment.bucket,
        Key: 'layout-jovenes/becario_tmp.json'
      }

  constructor(private localstorageService: LocalStorageService,
              private generalesService: GeneralesService,
              private accionesService: AccionesService,
              private ngxService: NgxUiLoaderService,
              private procesarService: ProcesarService,
              private becarioService: BecarioService,
              private testService: TestService) { }

  ngOnInit() {
    AWS.config.credentials = new AWS.Credentials(environment.awsConfig);

    this.ngxService.start();
    this.user = this.localstorageService.getUser();
    this.getGenerales();
    this.getListadoBecarios(this.user.idEmpresa);

    this.subscription = this.testService.subject.subscribe((data: any) => {
      this.ngxService.start();
      this.user=data;
      this.getGenerales();
      this.getListadoBecarios(this.user.idEmpresa);
      error => {
        this.errorSwal.show();
        this.ngxService.stop();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getGenerales() {
    this.generalesService.getGenerales(this.user.idEmpresa, 2).subscribe(
      res => {
        this.generales = res.body;
        this.changeBecarios.emit(true);
        this.changeBajas.emit(true);
      },
      error => {
        this.errorSwal.show();
      }
    );
  }

  /**
   * Muestra la ficha del postulante
   *
   * @param postulante
   */
  showFicha(becario) {
    this.becario = becario;
  }

  rechazaBecario($event) {
    this.becario = $event;
  }

  showRegistro($event) {
    this.registro = $event;
  }

  showError() {
    this.errorSwal.show();
  }

  bajaBecario($event) {
    this.ngxService.start();
    const declinacion = $event;
    declinacion.folio = this.becario.folio;
    declinacion.usuarioModifica = this.user.username + '';
    declinacion.isBaja = true;
    this.accionesService.declinar(declinacion).subscribe(
      res => {
        this.procesarService.cambioPendientes.emit(true);
        this.getGenerales();
        this.getListadoBecarios(this.user.idEmpresa);
        this.okBajaSwal.show();
        this.ngxService.stop();
      }, error => {
        this.errorSwal.show();
      }
    );
    this.ngxService.stop();
  }

  okJustificacion() {
    this.actualiza = !this.actualiza;
  }

  showBecarios() {
    this.becariosClass = 'selected active';
    this.becariosContentClass = 'show active';
    this.evaluacionesClass = '';
    this.evaluacionesContentClass = '';
    this.bajasClass = '';
    this.bajasContentClass = '';
  }

  showEvaluaciones() {
    this.becariosClass = '';
    this.becariosContentClass = '';
    this.evaluacionesClass = 'selected active';
    this.evaluacionesContentClass = 'show active';
    this.bajasClass = '';
    this.bajasContentClass = '';
  }

  showBajas() {
    this.becariosClass = '';
    this.becariosContentClass = '';
    this.evaluacionesClass = '';
    this.evaluacionesContentClass = '';
    this.bajasClass = 'selected active';
    this.bajasContentClass = 'show active';
  }

  getListadoBecarios(idEmpresa: number) {
    this.becarioService.getBecarios(idEmpresa).subscribe(
      res => {
        this.readS3File();
      }, error => {
        this.errorSwal.show();
        this.ngxService.stop();
      });
  }

  readS3File() {
    let s3 = new AWS.S3();
    let tmpStr: string;

    s3.getObject(this.params, (err, data) => {
      if(err) {
        console.log(err);
        this.ngxService.stop();
      } else {
        tmpStr = new TextDecoder('utf-8').decode(data.Body);
        this.createBecarios(tmpStr);
      }
    });
  }

  createBecarios(becariosStr: string) {

    let json = JSON.parse(becariosStr);
    this.becariosList = new Array();
    this.becariosBajaList = new Array();

    json.forEach(elem => {
      this.becario = new Becario();

      this.becario.idBecario = elem.idUsuario;
      this.becario.entidad = elem.entidadBean['descripcion'];
      this.becario.folio = elem.folio;
      this.becario.nombre = elem.nombre;
      this.becario.aPaterno = elem.apellidoPaterno;
      this.becario.aMaterno = elem.apellidoMaterno;
      this.becario.curp = elem.curp;
      this.becario.correo = elem.correo;
      this.becario.entidad = elem.entidadBean['descripcion'];
      this.becario.direccion = elem.direccion;
      this.becario.calle = elem.calle;
      this.becario.numExt = elem.noExterior;
      this.becario.numInt = elem.noInterior;
      this.becario.colonia = elem.colonia;
      this.becario.municipio = elem.municipio;
      this.becario.cp = elem.cp;
      this.becario.telefono1 = elem.telefono1;
      this.becario.nombreContacto = elem.nombreContacto;
      this.becario.telefono2 = elem.telefono2;
      this.becario.nombreCompleto = (elem.nombreCompleto) ? elem.nombreCompleto :
        elem.nombre + ' ' + elem.apellidoPaterno + ' ' + elem.apellidoMaterno;
      this.becario.centro = elem.centroBean['nombre'];
      this.becario.idCentro = elem.centroBean['idCentro'];
      this.becario.entidadCentro = elem.centroBean['entidadBean'].descripcion;
      this.becario.plan = elem.planCapacitacionBean['capacitar'];
      this.becario.tutor = elem.tutoresBean['nombre'];
      this.becario.idTutor = elem.tutoresBean['idUsuario'];
      this.becario.fechaPostulacion = elem.postulacioBean['fechaPostulacion'];
      this.becario.fechaAceptacion = elem.postulacioBean['fechaAceptacion'];
      this.becario.fechaIngreso = elem.postulacioBean['fechaInicioCap'];
      this.becario.estatus = elem.postulacioBean['estatusPostulacionBean'].descripcion;
      this.becario.idEstatus = elem.postulacioBean['idEstatusPostulacion'];
      this.becario.motivo = elem.postulacioBean['motivosBean'].descripcion;
      this.becario.observaciones = elem.postulacioBean['observaciones'];
      this.becario.empresa = elem.empresaBean['nombre'];
      this.becario.periodoCurso = 'Mes ' + elem.postulacioBean['mes'];
      this.becario.ultimaActualizacion = elem.ultima_modificacion;
      if(this.becario.idEstatus == 4 || this.becario.idEstatus == 5) {
        this.becariosBajaList.push(this.becario);
      } else {
        this.becariosList.push(this.becario);
      }
    });
    this.ngxService.stop();
  }
}
