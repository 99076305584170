import { PlanesService } from 'src/app/services/planes/planes.service';
import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { User } from 'src/app/model/user';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PlanResumen } from './../../../../model/PlanResumen';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-listado-planes',
  templateUrl: './listado-planes.component.html',
  styleUrls: ['./listado-planes.component.scss']
})
export class ListadoPlanesComponent implements OnInit {

  @ViewChild('okCreaPlanSwal') private okCreaPlanSwal: SwalComponent;
  @ViewChild('okModificaPlanSwal') private okModificaPlanSwal: SwalComponent;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  @ViewChild('eliminarPlanSwal') private eliminarPlanSwal: SwalComponent;
  @ViewChild('okEliminaPlanSwal') private okEliminaPlanSwal: SwalComponent;
  @ViewChild('errorEliminarPlanSwal') private errorEliminarPlanSwal: SwalComponent;

  changePlan: EventEmitter<PlanResumen> = new EventEmitter();
  updateEvent: EventEmitter<boolean> = new EventEmitter();

  user: User;
  plan = new PlanResumen();
  editar = false;

  constructor(private localstorageService: LocalStorageService,
    private planesService: PlanesService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.user = this.localstorageService.getUser();
  }

  verPlan($event) {
    this.plan = $event;
    this.editar = false;
    this.changePlan.emit(this.plan);
  }

  editarPlan($event) {
    this.plan = $event;
    this.editar = true;
    this.changePlan.emit(this.plan);
  }

  confirmaEliminarPlan($event) {
    this.plan = $event;
    this.eliminarPlanSwal.show();
  }

  eliminaPlan() {
    this.ngxService.start();    
    this.planesService.eliminaPlan(this.plan.planObj).subscribe(
      res => {
        if (res.body === 1) {
          this.ngxService.stop();
          this.okEliminaPlanSwal.show();
          this.updateEvent.emit(true);
        } else {
          this.ngxService.stop();
          this.errorEliminarPlanSwal.show();
        }
      }, error => {
        this.ngxService.stop();
        this.errorSwal.show();
      }
    );
  }

  updatePlan($event) {
    let tipo: number;    
    if (this.editar && $event.idPlan) {
      tipo = 1;      
      this.plan.planObj.idEstatus = 4;
    } else {
      tipo = 2;
      this.plan.planObj.idEstatus = 1;
    }  
    this.planesService.updatePlan($event, tipo).subscribe(
      res => {
        if (tipo === 1) {
          this.okModificaPlanSwal.show();
        } else if (tipo === 2) {
          this.okCreaPlanSwal.show();          
        }
        this.ngxService.stop();
        this.updateEvent.emit(true);
      }, error => {
        this.errorSwal.show();
        this.ngxService.stop();
      }
    );

  }

}
