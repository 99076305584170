import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TextMaskModule } from 'angular2-text-mask';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BecariosListadoComponent } from './components/becarios/becarios-listado/becarios-listado.component';
import { FichaBecarioComponent } from './components/becarios/ficha-becario/ficha-becario.component';
import { FichaDeclinacionBecarioComponent } from './components/becarios/ficha-declinacion-becario/ficha-declinacion-becario.component';
import { TablaBecariosComponent } from './components/becarios/tabla-becarios/tabla-becarios.component';
import { CargarRegistrosComponent } from './components/cargar-registros/cargar-registros.component';
import { EvcCentrosComponent } from './components/catalogos/centros/evc-centros/evc-centros.component';
import { ListadoCentrosComponent } from './components/catalogos/centros/listado-centros/listado-centros.component';
import { TablaCentrosComponent } from './components/catalogos/centros/tabla-centros/tabla-centros.component';
import { EvcPlancentroComponent } from './components/catalogos/plan-centro/evc-plancentro/evc-plancentro.component';
import { PlancentroListadoComponent } from './components/catalogos/plan-centro/plancentro-listado/plancentro-listado.component';
import { PlancentroTablaComponent } from './components/catalogos/plan-centro/plancentro-tabla/plancentro-tabla.component';
import { EvcModalComponent } from './components/catalogos/planes/evc-modal/evc-modal.component';
import { ListadoPlanesComponent } from './components/catalogos/planes/listado-planes/listado-planes.component';
import { TablaPlanesComponent } from './components/catalogos/planes/tabla-planes/tabla-planes.component';
import { EvcTutoresComponent } from './components/catalogos/tutores/evc-tutores/evc-tutores.component';
import { ListadoTutoresComponent } from './components/catalogos/tutores/listado-tutores/listado-tutores.component';
import { TablaTutoresComponent } from './components/catalogos/tutores/tabla-tutores/tabla-tutores.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { FichaDeclinacionComponent } from './components/postulantes/ficha-declinacion/ficha-declinacion.component';
import { FichaPostulanteComponent } from './components/postulantes/ficha-postulante/ficha-postulante.component';
import { PostulantesListadoComponent } from './components/postulantes/postulantes-listado/postulantes-listado.component';
import { TablaPostulacionesComponent } from './components/postulantes/tabla-postulaciones/tabla-postulaciones.component';
import { AutocompleteContentDirective } from './components/util/autocomplete-content.directive';
import { AutocompleteDirective } from './components/util/autocomplete.directive';
import { AutocompleteComponent } from './components/util/autocomplete/autocomplete.component';
import { OptionComponent } from './components/util/autocomplete/option/option.component';
import { FilterPipe } from './pipes/filter.pipe';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule, MatInputModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { FichaAsistenciaComponent } from './components/becarios/ficha-asistencia/ficha-asistencia.component';
import { FichaJustificacionComponent } from './components/becarios/ficha-justificacion/ficha-justificacion.component';
import { ProcesarListadoComponent } from './components/procesarJCF/procesar-listado/procesar-listado.component';
import { TablaProcesarComponent } from './components/procesarJCF/tabla-procesar/tabla-procesar.component';
import { PopoverModule } from 'ngx-popover';
import {UserIdleModule} from 'angular-user-idle';
import { HighchartsChartModule  } from 'highcharts-angular';
import { ReportesComponent } from './components/resportesGenerales/reportes/reportes.component';
import { UsuarioComponent } from './components/usuario/usuario.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    CargarRegistrosComponent,
    PostulantesListadoComponent,
    BecariosListadoComponent,
    FichaPostulanteComponent,
    FichaDeclinacionComponent,
    TablaPostulacionesComponent,
    ListadoCentrosComponent,
    ListadoTutoresComponent,
    ListadoPlanesComponent,
    TablaPlanesComponent,
    TablaBecariosComponent,
    FichaBecarioComponent,
    FichaDeclinacionBecarioComponent,
    EvcModalComponent,
    TablaTutoresComponent,
    EvcTutoresComponent,
    TablaCentrosComponent,
    EvcCentrosComponent,
    PlancentroListadoComponent,
    PlancentroTablaComponent,
    EvcPlancentroComponent,
    OptionComponent,
    AutocompleteComponent,
    AutocompleteDirective,
    AutocompleteContentDirective,
    FilterPipe,
    FichaAsistenciaComponent,
    FichaJustificacionComponent,
    ProcesarListadoComponent,
    TablaProcesarComponent,
    ReportesComponent,
    UsuarioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule,
    NgxUiLoaderModule,
    NgxPaginationModule,
    TextMaskModule,
    OverlayModule,
    PopoverModule,
    Ng2SearchPipeModule,
    HighchartsChartModule,
    SweetAlert2Module.forRoot(),
    UserIdleModule.forRoot ({idle: 3600, timeout: 1800, ping: 300})
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [UsuarioComponent,
                    FichaBecarioComponent,
                    FichaAsistenciaComponent]
})

export class AppModule { }

