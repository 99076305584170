import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Plan } from 'src/app/model/Plan';
import { PlanResumen } from 'src/app/model/PlanResumen';
import { TipoCapacitacion } from 'src/app/model/TipoCapacitacion';
import { TipoDiscapacidad } from 'src/app/model/TipoDiscapacidad';
import { TipoEscolaridad } from 'src/app/model/TipoEscolaridad';
import { TipoDiscapacidadService } from 'src/app/services/catalogos/tipo-discapacidad.service';
import { TipoEscolaridadService } from 'src/app/services/catalogos/tipo-escolaridad.service';
import { TipoCapacitacionService } from './../../../../services/catalogos/tipo-capacitacion.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-evc-modal',
  templateUrl: './evc-modal.component.html',
  styleUrls: ['./evc-modal.component.scss']
})
export class EvcModalComponent implements OnInit {

  @Input() editar: boolean;
  @Input() updatePlan: EventEmitter<PlanResumen>;

  @Output() updateEvent = new EventEmitter();

  @ViewChild('closeDialog') closeDialog: ElementRef;

  catTC: TipoCapacitacion[];
  catTE: TipoEscolaridad[];
  catTD: TipoDiscapacidad[];
  plan: PlanResumen;

  evcForm: FormGroup;
  submitedEvc = false;

  constructor(private tipoCapacitacionService: TipoCapacitacionService,
    private tipoEscolaridadService: TipoEscolaridadService,
    private tipoDiscapacidadService: TipoDiscapacidadService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private ngxService: NgxUiLoaderService ) { }

  ngOnInit() {
    this.ngxService.start();
    this.plan = new PlanResumen();
    this.plan.planObj = new Plan();

    this.evcForm = this.formBuilder.group({
      fieldNombre: ['', Validators.required],
      fieldHabilidades: ['', Validators.required],
      fieldTipoCapacitacion: ['', Validators.required],
      fieldEscolaridad: ['', Validators.required],
      fieldDiscapacidad: ['', Validators.required],
      fieldPalabrasClave: ['', Validators.required],
      fieldDescActividad: ['', Validators.required],
      fieldEstatus: ['', Validators.required],
    });

    this.updatePlan.subscribe(res => {
      this.refrescaPlan(res);
      this.ngxService.stop();
    });

    this.tipoCapacitacionService.getTiposCapacitacion(1).subscribe(
      res => {
        this.catTC = res.body;
      }, error => {

      }
    );

    this.tipoEscolaridadService.getTiposEscolaridad(1).subscribe(
      res => {
        this.catTE = res.body;
      }, error => {

      }
    );

    this.tipoDiscapacidadService.getTiposDiscapacidad(1).subscribe(
      res => {
        this.catTD = res.body;
      }, error => {
      }
    );
  }

  guardaPlan(tipo: number) {
    this.submitedEvc = true;
    if (this.evcForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      this.plan.planObj.capacitar = this.evcForm.get('fieldNombre').value;
      this.plan.planObj.habilidades = this.evcForm.get('fieldHabilidades').value;
      this.plan.planObj.idTipoCapacitacion = this.evcForm.get('fieldTipoCapacitacion').value;
      this.plan.planObj.idTipoEscolaridad = this.evcForm.get('fieldEscolaridad').value;
      this.plan.planObj.idTipoDiscapacidad = this.evcForm.get('fieldDiscapacidad').value;
      this.plan.planObj.palabraClave = this.evcForm.get('fieldPalabrasClave').value;
      this.plan.planObj.desActividad = this.evcForm.get('fieldDescActividad').value;
      this.plan.planObj.idEstatus = this.evcForm.get('fieldEstatus').value;
      this.plan.planObj.user_modifica = this.localStorageService.getUser().username;

      
      this.closeDialog.nativeElement.click();

      this.updateEvent.emit(this.plan.planObj);
    }
  }

  refrescaPlan(plan: PlanResumen) {
    this.plan = plan;
    this.evcForm.get('fieldNombre').patchValue(plan.planObj.capacitar);
    this.evcForm.get('fieldHabilidades').patchValue(plan.planObj.habilidades);
    this.evcForm.get('fieldTipoCapacitacion').patchValue(plan.planObj.idTipoCapacitacion);
    this.evcForm.get('fieldEscolaridad').patchValue(plan.planObj.idTipoEscolaridad);
    this.evcForm.get('fieldDiscapacidad').patchValue(plan.planObj.idTipoDiscapacidad);
    this.evcForm.get('fieldPalabrasClave').patchValue(plan.planObj.palabraClave);
    this.evcForm.get('fieldDescActividad').patchValue(plan.planObj.desActividad);
    this.evcForm.get('fieldEstatus').patchValue(plan.planObj.idEstatus);

  }

  get f() { return this.evcForm.controls; }

}
