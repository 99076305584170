import { LocalStorageService } from './../../services/local-storage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoService } from 'src/app/services/cognito.service';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/services/login.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  folioMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  @ViewChild('noTutorSwal') private noTutorSwal: SwalComponent;
  @ViewChild('sesionActiva') private sesionActiva: SwalComponent;

  

  constructor(private formBuilder: FormBuilder,
    private cognito: CognitoService,
    private loginService: LoginService,
    private localstorageService: LocalStorageService,
    private ngxService: NgxUiLoaderService,
    private router: Router) { }

  get f() { return this.loginForm.controls; }

  loginForm: FormGroup;
  submitedLogin = false;

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      fieldFolio: ['', Validators.compose([Validators.required, Validators.minLength(3), this.numEmpleadoCorreoValidator])],
      fieldPassword: ['', Validators.required],
    });
  }

  autenticar() {
    this.submitedLogin = true;    
    if (this.loginForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      this.cognito.login(environment.auth.user, environment.auth.secret).subscribe(res => {               
        this.loginService.authenticate(this.f.fieldFolio.value, this.f.fieldPassword.value)
          .subscribe(
            res2 => {               
               localStorage.setItem('id', ''+res2.body.finumeroempleado ); 
                if (res2.body.finumeroempleado > 0) {    
                 
                  if(res2.body.idConecta === 1){                           
                      this.sesionActiva.show();                         
                      //localStorage.setItem('id', ''+res2.body.finumeroempleado );                                               
                     }else{
                      this.loginService.conecta(res2.body.finumeroempleado,1).subscribe(res3=>{                  
                      })                      
                  }     
                  this.localstorageService.saveUser(res2.body);
                  this.ngxService.stop();
                  this.router.navigateByUrl('/dashboard');      
                  $('body').css('overflow-y', 'scroll');       
                } else {
                  this.ngxService.stop();
                  this.noTutorSwal.show();
                }                   
            }, err2 => {
              this.ngxService.stop();
              this.noTutorSwal.show();
            }
          );
      }, err => {
        this.ngxService.stop();
        console.log('Error en el login: ' + err);
      });
    }
  }

  /**
   * Valida que el campo sea numérico o un correo
   * @param control
   *
   */
  numEmpleadoCorreoValidator(control: FormControl) {
    const value = control.value;
    if (isNaN(Number(value))) {
      if (value && value.indexOf('@') !== -1) {
        return null;
      } else {
        return { numEmpleadoCorreoValidator: 'Valor no válido' };
      }
    } else {
      return null;
    }
  }

}
