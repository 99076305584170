import { Injectable } from '@angular/core';
import { CentroVO } from 'src/app/model/CentroVO';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CentroService {

  apiURLListado = `${environment.apiURL}centros/listado`;
  apiURLInsert = `${environment.apiURL}centros/actualizacreacentro`;
  apiURLDelete = `${environment.apiURL}centros/cambiaestatus`;
  apiURLDownload = `${environment.apiURL}descargascsv`;

  constructor(private http: HttpClient) { }

  /**
   * Obtiene el listado de centros por empresa
   * @param idEmpresa Id de la empresa a buscar
   */
  getCentrosListado(idEmpresa: number) {
    const data = { idEmpresa };
    return this.http.post<CentroVO[]>(this.apiURLListado, data, { observe: 'response' });
  }

  /**
   * Actualiza o crea un tutor
   * @param centro Objeto de tipo CentroVO a ser actualizado o creado
   * @param tipo Tipo de acción a realizar [ 1 - Actualizar, 2 - Crear ]
   */
  updateCentro(centro: CentroVO, tipo: number) {
    let r:number;
        
    if(tipo==1){
      r= 4
    }else{
      r= 3
    }

    const data = {
      idCentro: centro.idCentro,
      folio: centro.folio,
      nombre: centro.nombre,
      cp: centro.cp,
      idEntidad: centro.idEntidad,
      colonia: centro.colonia,
      calle: centro.calle,
      noExterior: centro.noExterior,
      noInterior: centro.noInterior,
      longitude: centro.longitude,
      latitude: centro.latitude,
      idEmpresa: centro.idEmpresa,
      idEstatus: r,
      user_modifica: centro.user_modifica,
      idOperacion: tipo
    };
    return this.http.post(this.apiURLInsert, data, { observe: 'response' });
  }

  /**
   * Desactiva al tutor
   * @param tutor Objeto de tipo TutorVO
   */
  eliminaCentro(centro: CentroVO, accion: number) {
    const data = {
      idCentro: centro.idCentro,
      idEstatus: accion,
      userModifica: centro.user_modifica
    };
    return this.http.post<number>(this.apiURLDelete, data, { observe: 'response' });
  }

  /**
     * Descarga el archivo de centros
     * @param idEmpresa identificador de la empresa
     * @param tipo tipo de archivo a descargar
     */
  downloadArchivo(idEmpresa: number, tipo: number) {
    const data = {
      tipo, idEmpresa
    };
    return this.http.post(this.apiURLDownload, data, { observe: 'response' });
  }

}
