import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Becario } from 'src/app/model/Becario';
import { Buscador } from 'src/app/utils/buscador';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { BecarioService } from 'src/app/services/becario/becario.service';
import { MatDialog } from '@angular/material/dialog';
import { FichaBecarioComponent } from 'src/app/components/becarios/ficha-becario/ficha-becario.component';
import { FichaAsistenciaComponent } from 'src/app/components/becarios/ficha-asistencia/ficha-asistencia.component';

@Component({
  selector: 'app-tabla-becarios',
  templateUrl: './tabla-becarios.component.html',
  styleUrls: ['./tabla-becarios.component.scss']
})
export class TablaBecariosComponent implements OnInit {

  @ViewChild('term') term: ElementRef;
  @ViewChild('numRegistrosElem') numRegistrosElem: ElementRef;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;

  @Input() private tipo: number;
  @Input() private becarios: Array<{ [key: string]: any }>;
  @Input() private becariosO: Array<{ [key: string]: any }>;
  @Input() private idEmpresa: number;
  @Input() private updateTable: EventEmitter<boolean>;

  @Output() private fichaEvent = new EventEmitter<Becario>();
  @Output() private rechazaEvent = new EventEmitter<Becario>();

  becario: Becario;
  numRegistros = 10;
  idPaginator: string;
  buscador = new Buscador();
  archivo: number;

  constructor(private ngxService: NgxUiLoaderService,
              private becarioService: BecarioService,
              private becarioDialog: MatDialog,
              private asistenciaDialog: MatDialog) { }

  ngOnInit() {
    this.idPaginator = 'id' + this.tipo;
  }

  showFichaBecario(pos: Becario) {
    let dialogRef = this.becarioDialog.open(FichaBecarioComponent, {
      id: 'becarioModal',
      autoFocus: false,
      maxWidth: '100vw',
      panelClass: 'modal'
    });
    let instance = dialogRef.componentInstance;
    instance.becario = pos;
  }

  showFichaAsistencia(pos: Becario) {
    this.ngxService.start();
    let dialogRef = this.asistenciaDialog.open(FichaAsistenciaComponent, {
      id: 'asistenciaModal',
      autoFocus: false,
      maxWidth: '100vw',
      panelClass: 'modal'
    });
    let instance = dialogRef.componentInstance;
    instance.becario = pos;
  }

  rechazaBecario(becario: Becario) {
    this.rechazaEvent.emit(becario);
  }

  /**
   * Filtra de acuerdo a los parámetros recibidos
   */
  filtra() {
    const term = this.term.nativeElement.value;
    this.becarios = this.buscador.filter(this.becariosO, term);
  }

  descargaArchivo() {
    this.ngxService.start();
      if(this.tipo == 1) {
        this.archivo = 2;
      } else {
        this.archivo = 6;
      }
    this.becarioService.downloadArchivo(this.idEmpresa, this.archivo).subscribe(
      res => {
        window.open(res.body['urlInicial'], '_blank');
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
      }
    );
  }

  updateNumRegistros() {
    this.numRegistros = this.numRegistrosElem.nativeElement.value;
  }

}
