import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TutorVO } from 'src/app/model/TutorVO';

@Injectable({
  providedIn: 'root'
})
export class TutorService {

  apiURLListado = `${environment.apiURL}user/tutor/listado`;
  apiURLInsert = `${environment.apiURL}user/tutor/actualizacrea`;
  apiURLDelete = `${environment.apiURL}user/cambio-estatus`;
  apiURLDownload = `${environment.apiURL}descargascsv`;

  constructor(private http: HttpClient) { }

  /**
   * Obtiene el listado de tutores por empresa
   * @param idEmpresa Id de la empresa a buscar
   */
  getTutoresListado(idEmpresa: number) {
    const data = { idEmpresa };
    return this.http.post<TutorVO[]>(this.apiURLListado, data, { observe: 'response' });
  }

  /**
   * Actualiza o crea un tutor
   * @param tutor Objeto de tipo TutorVO a ser actualizado o creado
   * @param tipo Tipo de acción a realizar [ 1 - Actualizar, 2 - Crear ]
   */
  updateTutor(tutor: TutorVO, tipo: number) {
    let r:number;

    if(tipo==1){
      r= 4
    }else{
      r= 3
    }

    const data = {
      idUsuario: tutor.idUsuario,
      noSocio: tutor.noSocio,
      nombre: tutor.nombre,
      apellidoPaterno: tutor.apellidoPaterno,
      apellidoMaterno: tutor.apellidoMaterno,
      correo: tutor.correo,
      idEmpresa: tutor.idEmpresa,
      observaciones: tutor.observaciones,
      celular: tutor.celular,
      user_modifica: tutor.user_modifica,
      idOperacion: tipo
    };
    return this.http.post<TutorVO>(this.apiURLInsert, data, { observe: 'response' });
  }

  /**
   * Desactiva al tutor
   * @param tutor Objeto de tipo TutorVO
   */
  eliminaTutor(tutor: TutorVO, accion: number) {
    const data = {
      idUsuario: tutor.idUsuario,
      idEstatus: accion,
      idPerfil: 2,
      userModifica: tutor.user_modifica
    };
    return this.http.post<number>(this.apiURLDelete, data, { observe: 'response' });
  }

  /**
     * Descarga el archivo de tutores
     * @param idEmpresa identificador de la empresa
     * @param tipo tipo de archivo a descargar
     */
    downloadArchivo(idEmpresa: number, tipo: number) {
      const data = {
        tipo, idEmpresa
      };
      return this.http.post(this.apiURLDownload, data, { observe: 'response' });
    }

}
