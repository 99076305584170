import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PendienteProcesarVO } from 'src/app/model/PendienteProcesarVO';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcesarService {

  private apiURLListado = `${environment.apiURL}catalogos/jcflistado`;
  private apiURLCambiaEstatus = `${environment.apiURL}alertas/actualiza`;
  private apiURLConteoPendientes = `${environment.apiURL}alertas/conteo`;
  private apiURLDownload = `${environment.apiURL}descargascsv`;

  cambioPendientes = new EventEmitter<boolean>();

  constructor(private http: HttpClient) { }

  getListado(idEmpresa: number) {
    const data = { idEmpresa };
    return this.http.post<PendienteProcesarVO[]>(this.apiURLListado, data, { observe: 'response' });
  }

  cambiaEstatus(idAlerta: number, usuario_modifica: string) {
    const data = { idEstatus: 2, idAlerta, usuario_modifica };
    const obj = this.http.post(this.apiURLCambiaEstatus, data, { observe: 'response' });
    return obj;
  }

  conteoPendientes(idEmpresa: number) {
    const data = { idEmpresa };
    return this.http.post(this.apiURLConteoPendientes, data, { observe: 'response' });
  }

  descargaArchivo(idEmpresa: number) {
    const data = { idEmpresa, tipo: 9};
    return this.http.post(this.apiURLDownload, data, { observe: 'response' });
  }

}
