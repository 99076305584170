export class Plan {
  idPlan: number;
  habilidades: string;
  capacitar: string;
  idTipoCapacitacion: number;
  idTipoEscolaridad: number;
  idTipoDiscapacidad: number;
  desActividad: string;
  palabraClave: string;
  nombreArchivo: string;
  formato: string;
  url: string;
  noJovenes: number;
  horaTrabajo: number;
  tipoCapacitacion: string;
  tipoEscolaridad: string;
  tipoDiscapacidad: string;
  ultima_modificacion: Date;
  idEstatus: number;
  user_modifica: string;  
}
