import { TutorService } from 'src/app/services/tutores/tutor.service';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { TutorVO } from 'src/app/model/TutorVO';
import { User } from 'src/app/model/user';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-listado-tutores',
  templateUrl: './listado-tutores.component.html',
  styleUrls: ['./listado-tutores.component.scss']
})
export class ListadoTutoresComponent implements OnInit {

  @ViewChild('okCreaTutorSwal') private okCreaTutorSwal: SwalComponent;
  @ViewChild('okModificaTutorSwal') private okModificaTutorSwal: SwalComponent;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  @ViewChild('eliminarTutorSwal') private eliminarTutorSwal: SwalComponent;
  @ViewChild('activarTutorSwal') private activarTutorSwal: SwalComponent;
  @ViewChild('okEliminaTutorSwal') private okEliminaTutorSwal: SwalComponent;
  @ViewChild('okActivaTutorSwal') private okActivaTutorSwal: SwalComponent;
  @ViewChild('errorEliminarTutorSwal') private errorEliminarTutorSwal: SwalComponent;

  changeTutor: EventEmitter<TutorVO> = new EventEmitter();
  updateEvent: EventEmitter<boolean> = new EventEmitter();

  user: User;
  tutor = new TutorVO();
  editar = false;

  constructor(private localstorageService: LocalStorageService,
    private tutoresService: TutorService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.user = this.localstorageService.getUser();
  }

  verTutor($event) {
    this.tutor = $event;
    this.editar = false;

    this.changeTutor.emit(this.tutor);
  }

  editarTutor($event) {
    this.tutor = $event;
    this.editar = true;
    this.changeTutor.emit(this.tutor);
  }

  confirmaEliminarTutor($event) {
    this.tutor = $event;
    if (this.tutor.idEstatus === 1) {
      this.eliminarTutorSwal.show();
    } else {
      this.activarTutorSwal.show();
    }
  }

  eliminaTutor(accion: number) {
    this.ngxService.start();
    this.tutor.user_modifica = this.user.username;
    this.tutoresService.eliminaTutor(this.tutor, accion).subscribe(
      res => {
        if (!res.body['error']) {
          this.ngxService.stop();

          if (accion === 1) {
            this.okActivaTutorSwal.show();
          } else {
            this.okEliminaTutorSwal.show();
          }
          this.updateEvent.emit(true);
        } else {
          this.ngxService.stop();
          this.errorEliminarTutorSwal.show();
        }
      }, error => {
        this.ngxService.stop();
        this.errorSwal.show();
      }
    );
  }

  updateTutor($event: TutorVO) {
    let tipo: number;    
    if (this.editar && $event.idUsuario) {
      tipo = 1;
    } else {
      tipo = 2;
    }

    $event.idEmpresa = this.user.idEmpresa;
    $event.user_modifica = this.user.username;

    this.tutoresService.updateTutor($event, tipo).subscribe(
      res => {
        if (tipo === 2) {
          this.okCreaTutorSwal.show();
        } else if (tipo === 1) {
          this.okModificaTutorSwal.show();
        }
        this.ngxService.stop();
        this.updateEvent.emit(true);
      }, error => {
        this.errorSwal.show();
        this.ngxService.stop();
      }
    );

  }

}
