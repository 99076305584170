import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {
  
  apiURLDownload = `${environment.apiURL}reportesjcf/reporteusuarios`;

  constructor(private http: HttpClient) { }

    /**
     * Descarga el archivo de reportes
     * @param idEmpresa identificador de la empresa
     * @param tipo reporte tipo de archivo a descargar
     */
    generaExcel(idEmpresa: number, idTipo: number) {
      const data = {idTipo, idEmpresa};
      return this.http.post(this.apiURLDownload, data, { observe: 'response' });
    }

 
  /**
     * Descarga el archivo de reportes
     * @param idEmpresa identificador de la empresa
     * @param tipo reporte tipo de archivo a descargar
     */
  downloadArchivo(idEmpresa: number, tipo: number) {
    const data = {
      tipo, idEmpresa
    };
    return this.http.post(this.apiURLDownload, data, { observe: 'response' });
  }
  
}
