import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Generales } from 'src/app/model/generales';

@Injectable({
  providedIn: 'root'
})
export class GeneralesService {

  apiURL = `${environment.apiURL}dashboard/totalcantidad`;
  apiURLM = `${environment.apiURL}dashboard/mapa`;
  apiURLE = `${environment.apiURL}dashboard/escolaridad`;
  apiURLG = `${environment.apiURL}dashboard/genero`;
  apiURLEdad = `${environment.apiURL}dashboard/edad`;
  apiURLHS = `${environment.apiURL}dashboard/historialsemana`;
  apiURLMBD = `${environment.apiURL}dashboard/motivosbajadeclinado`;  


  constructor(private http: HttpClient) { }

  getGenerales(idEmpresa, idPeticion) {
    const data = {idUser: 1, idEmpresa, userModifica: 1, idPeticion};
    return this.http.post<Generales>(this.apiURL, data, { observe: 'response' });
  }

  getDatosPorEstado(idEmpresa,idTipo) {
    const data = {idEmpresa,idTipo};
    return this.http.post(this.apiURLM, data, { observe: 'response' });
  } 

  getDatosPorEscolaridad(idEmpresa,idTipo) {
    const data = {idEmpresa,idTipo};
    return this.http.post(this.apiURLE, data, { observe: 'response' });
  } 


  getDatosGenero(idEmpresa,idTipo) {
    const data = {idEmpresa,idTipo};
    return this.http.post(this.apiURLG, data, { observe: 'response' });
  } 

  getDatosEdad(idEmpresa,idTipo) {
    const data = {idEmpresa,idTipo};
    return this.http.post(this.apiURLEdad, data, { observe: 'response' });
  }


  getDatosHistorial(idEmpresa,idTipo) {
    const data = {idEmpresa,idTipo};
    return this.http.post(this.apiURLHS, data, { observe: 'response' });
  }


  getDatosMotivosBD(idEmpresa,idTipo) {   
    const data = {idEmpresa,idTipo};
    return this.http.post(this.apiURLMBD, data, { observe: 'response' });
  }
}
