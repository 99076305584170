import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostulantesService {

  apiURL = `${environment.apiURL}postulacion/xestatus`;
  apiURLDownload = `${environment.apiURL}descargascsv`;

  constructor(private http: HttpClient) { }

  getPostulantes(idEmpresa: number) {
    let  estatus = '[236]';

    const data = { idEmpresa, idEstatus: estatus };
    return this.http.post<any[]>(this.apiURL, data, { observe: 'response' });
  }

  /**
       * Descarga el archivo de tutores
       * @param idEmpresa identificador de la empresa
       * @param tipo tipo de archivo a descargar
       */
  downloadArchivo(idEmpresa: number, tipo: number) {
    const data = {
      tipo, idEmpresa
    };
    return this.http.post(this.apiURLDownload, data, { observe: 'response' });
  }

}
