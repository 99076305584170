import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Becario } from 'src/app/model/Becario';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ficha-becario',
  templateUrl: './ficha-becario.component.html',
  styleUrls: ['./ficha-becario.component.scss']
})
export class FichaBecarioComponent implements OnInit {

  @Input() becario: Becario;

  constructor(private dialogRef: MatDialogRef<FichaBecarioComponent>) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
