import { Component, OnInit, EventEmitter } from '@angular/core';
import { User } from 'src/app/model/user';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-procesar-listado',
  templateUrl: './procesar-listado.component.html',
  styleUrls: ['./procesar-listado.component.sass']
})
export class ProcesarListadoComponent implements OnInit {

  user: User;

  constructor(private localstorageService: LocalStorageService, ) { }

  ngOnInit() {
    this.user = this.localstorageService.getUser();
  }

}
