import { User } from './../model/user';
import { Injectable } from '@angular/core';
import { Tutor } from '../model/tutor';
import { userInfo } from 'os';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  /**
   * Guarda en localstorage los datos relevantes del usuario para la sesión
   * @param tutor
   */
  public saveUser(tutor: Tutor) {
    const user = new User();
    user.nombre = tutor.fcnombre + ' ' + tutor.fcapaterno + ' ' + tutor.fcamaterno;
    user.username = tutor.finumeroempleado.toString();
    user.idPerfil = tutor.fiidperfil;
    user.correo = tutor.fccorreo;
    user.nomEmpresa = tutor.nombre_emp;
    user.idEmpresa = tutor.fiidempresa;
    user.idFolio = tutor.idFolio;
    user.idUsuario = tutor.fiidusuario;
    user.idRol = tutor.idRol;
    user.idConecta= tutor.idConecta;

    localStorage.setItem('USER', JSON.stringify(user));
  }

  public modifyLocalUser(user: User) {
    localStorage.setItem('USER', JSON.stringify(user));
  }

  /**
   * Obtiene el objeto usuario del localstorage
   */
  public getUser(): User {
    const user: User = JSON.parse(localStorage.getItem('USER'));
    return user;
  }

  public deleteSession() {
    localStorage.clear();
  }

}
