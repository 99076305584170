export class Generales {
  noAceptado: number = 0;
  noNuevo: number = 0;
  noPendiente: number = 0;
  noDeclinado: number = 0;
  noEspacio: number = 0;
  noBaja: number = 0;
  noSolicitudesEntrevista: number = 0;
  noProcesando: number = 0;
  noPostulaciones: number;
  noBecariosActivos: number;
  noBecariosBaja: number;
  noBajasMensual: number;
  noCasosExito: number;
}
