import { BecarioAsistencia } from 'src/app/model/BecarioAsistencia';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Becario } from 'src/app/model/Becario';
import { BecarioService } from 'src/app/services/becario/becario.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ficha-asistencia',
  templateUrl: './ficha-asistencia.component.html',
  styleUrls: ['./ficha-asistencia.component.scss']
})
export class FichaAsistenciaComponent implements OnInit {

  @ViewChild('observacionesSwal') private observacionesSwal: SwalComponent;

  @Input() becario: Becario;
  @Input() actualiza: boolean;
  @Output() registroEvent = new EventEmitter();
  @Output() errorEvent = new EventEmitter();


  asistencias = new Array();
  meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

  fechaActual: Date;
  mesActual: number;
  anio: number;
  numMeses: number;
  mesesAtras: number;
  comentarios: string;

  constructor(private becarioService: BecarioService,
              private ngxService: NgxUiLoaderService,
              private dialogRef: MatDialogRef<FichaAsistenciaComponent>) { }

  ngOnInit() {
        this.asistencias = new Array();
        this.fechaActual = new Date();
        this.mesActual = this.fechaActual.getMonth() + 1;
        this.anio = this.fechaActual.getFullYear();
        this.numMeses = (this.becario.fechaIngreso) ?
          this.mesActual - (new Date(this.becario.fechaIngreso).getMonth() + 1) : 0;
        this.mesesAtras = this.numMeses;

        this.becarioService.consultaAsistencias(this.becario.idBecario, this.mesActual, this.anio).subscribe(
          res => {
            this.asistencias = res.body;
            this.ngxService.stop();
          }, err => {
            this.ngxService.stop();
            this.errorEvent.emit();
        });
  }

  mesAnterior() {
    this.ngxService.start();
    this.mesesAtras--;
    this.fechaActual.setMonth(this.fechaActual.getMonth() - 1);
    this.mesActual = this.fechaActual.getMonth() + 1;
    this.anio = this.fechaActual.getFullYear();
    this.becarioService.consultaAsistencias(this.becario.idBecario, this.mesActual, this.anio).subscribe(
      res => {
        this.asistencias = res.body;
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
        this.errorEvent.emit();
      }
    );
  }

  mesSiguiente() {
    this.ngxService.start();
    this.mesesAtras++;
    this.fechaActual.setMonth(this.fechaActual.getMonth() + 1);
    this.mesActual = this.fechaActual.getMonth() + 1;
    this.anio = this.fechaActual.getFullYear();
    this.becarioService.consultaAsistencias(this.becario.idBecario, this.mesActual, this.anio).subscribe(
      res => {
        this.asistencias = res.body;
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
        this.errorEvent.emit();
      }
    );
  }

  showFicha($event) {
    this.registroEvent.emit($event);
    this.dialogRef.close();
  }

  showComentarios($event) {
    this.comentarios = $event.observaciones;
    this.observacionesSwal.show();
  }

  close() {
    this.dialogRef.close();
  }

}
