import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Empresa } from '../model/Empresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {

  apiURL = `${environment.apiURL}catalogos/empresas`;

  constructor(private http: HttpClient) { }

  getEmpresas() {
    const data = null;
    return this.http.post<Empresa[]>(this.apiURL, data, { observe: 'response' });
  }

}
