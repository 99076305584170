import { ProcesarListadoComponent } from './components/procesarJCF/procesar-listado/procesar-listado.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BecariosListadoComponent } from './components/becarios/becarios-listado/becarios-listado.component';
import { CargarRegistrosComponent } from './components/cargar-registros/cargar-registros.component';
import { ListadoCentrosComponent } from './components/catalogos/centros/listado-centros/listado-centros.component';
import { PlancentroListadoComponent } from './components/catalogos/plan-centro/plancentro-listado/plancentro-listado.component';
import { ListadoPlanesComponent } from './components/catalogos/planes/listado-planes/listado-planes.component';
import { ListadoTutoresComponent } from './components/catalogos/tutores/listado-tutores/listado-tutores.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { PostulantesListadoComponent } from './components/postulantes/postulantes-listado/postulantes-listado.component';
import { AuthGuardService } from './services/auth-guard.service';
import {BorraCache} from './utils/deleteCache';
import { ReportesComponent } from './components/resportesGenerales/reportes/reportes.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [BorraCache] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'cargar-registros', component: CargarRegistrosComponent, canActivate: [AuthGuardService] },
  { path: 'postulantes-listado', component: PostulantesListadoComponent, canActivate: [AuthGuardService] },
  { path: 'becarios-listado', component: BecariosListadoComponent, canActivate: [AuthGuardService] },
  { path: 'centros-listado', component: ListadoCentrosComponent, canActivate: [AuthGuardService] },
  { path: 'planes-listado', component: ListadoPlanesComponent, canActivate: [AuthGuardService] },
  { path: 'tutores-listado', component: ListadoTutoresComponent, canActivate: [AuthGuardService] },
  { path: 'plancentro-listado', component: PlancentroListadoComponent, canActivate: [AuthGuardService] },
  { path: 'procesar-listado', component: ProcesarListadoComponent, canActivate: [AuthGuardService] },
  { path: 'reportes', component: ReportesComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
