import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CentroService } from 'src/app/services/centros/centro.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CentroVO } from 'src/app/model/CentroVO';
import { Buscador } from 'src/app/utils/buscador';

@Component({
  selector: 'app-tabla-centros',
  templateUrl: './tabla-centros.component.html',
  styleUrls: ['./tabla-centros.component.scss']
})
export class TablaCentrosComponent implements OnInit {

  @ViewChild('term') term: ElementRef;
  @ViewChild('numRegistrosElem') numRegistrosElem: ElementRef;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;

  @Input() private idEmpresa: number;
  @Input() private updateTable: EventEmitter<boolean>;
  @Output() private verEvent = new EventEmitter<CentroVO>();
  @Output() private editarEvent = new EventEmitter<CentroVO>();
  @Output() private eliminarEvent = new EventEmitter<CentroVO>();


  centros: Array<{ [key: string]: any }>;
  centrosO: Array<{ [key: string]: any }>;
  numRegistros = 10;
  idPaginator: string;
  buscador = new Buscador();
  centro: CentroVO;

  constructor(private centrosService: CentroService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {

    this.ngxService.start();
    this.idPaginator = 'idPager';
    this.centros = new Array();
    this.centrosO = new Array();

    this.actualizaTabla();
    this.updateTable.subscribe(res => {
      this.actualizaTabla();
    });

  }

  actualizaTabla() {
    this.centrosService.getCentrosListado(this.idEmpresa).subscribe(
      res => {
        this.centros = res.body;
        this.centrosO = res.body;
        this.ngxService.stop();
      }, error => {
        console.log(error);
        this.ngxService.stop();
      }
    );
  }

  showCentro(centro) {
    this.verEvent.emit(centro);
  }

  editarCentro(centro) {
    this.editarEvent.emit(centro);
  }

  updateNumRegistros() {
    this.numRegistros = this.numRegistrosElem.nativeElement.value;
  }

  confirmaEliminar(centro) {
    this.eliminarEvent.emit(centro);
  }

  abrirNuevo() {
    this.centro = new CentroVO();
    this.editarEvent.emit(this.centro);
  }

  descargaArchivo() {
    this.ngxService.start();
    this.centrosService.downloadArchivo(this.idEmpresa, 3).subscribe(
      res => {
        window.open(res.body['urlInicial'], '_blank');
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
      }
    );
  }

  /**
   * Filtra de acuerdo a los parámetros recibidos
   */
  filtra() {
    const term = this.term.nativeElement.value;
    this.centros = this.buscador.filter(this.centrosO, term);
  }

}
