import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MotivoDeclinacion } from 'src/app/model/MotivoDeclinacion';

@Injectable({
  providedIn: 'root'
})
export class DeclinarService {

  apiURLMotivos = `${environment.apiURL}catalogos/motivos-declinacion/web-becario`;

  constructor(private http: HttpClient) { }

  getMotivosDeclinacion(isBecario: boolean, isDeclinacion: boolean) {
    const data = {isBecario, isDeclinacion};
    return this.http.post<MotivoDeclinacion[]>(this.apiURLMotivos, data, { observe: 'response' });
  }

}
