import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CargaArchivoService {

  url = `${environment.apiURL}cargasmasivas/carga-archivo`;

  constructor(private http: HttpClient) { }

  uploadFile(file, fileName) {
    if (file.length > 0) {
      const base64File = file.split(',')[1];
      const data = { file: base64File, name: fileName };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
          // 'X-Amz-Security-Token': token.jwtToken,
        })
      };

      return this.http
        .post(this.url, data, httpOptions);
    }
  }

}
