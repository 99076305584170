import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Becario } from 'src/app/model/Becario';

@Component({
  selector: 'app-ficha-postulante',
  templateUrl: './ficha-postulante.component.html',
  styleUrls: ['./ficha-postulante.component.scss']
})
export class FichaPostulanteComponent implements OnInit {

  @Input() becario: Becario;

  @Output() aceptaEvent = new EventEmitter<Becario>();
  @Output() rechazaEvent = new EventEmitter<Becario>();

  @ViewChild('closeDialog') closeDialog: ElementRef;

  muestraAceptar: boolean;

  constructor() { }

  ngOnInit() {
  }

  aceptaPostulante() {
    this.closeDialog.nativeElement.click();
    this.aceptaEvent.emit(this.becario);
  }

  rechazaPostulante() {
    this.closeDialog.nativeElement.click();
    this.rechazaEvent.emit(this.becario);
  }

}
