import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Entidad } from 'src/app/model/Entidad';

@Injectable({
  providedIn: 'root'
})
export class EntidadesService {

  private apiURL = `${environment.apiURL}catalogos/entidades`;

  constructor(private http: HttpClient) { }

  getEntidades() {
    const data = {  };
    return this.http.post<Entidad[]>(this.apiURL, data, { observe: 'response' });
  }
}
