import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Crypt } from 'src/app/utils/crypt-util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tutor } from '../model/tutor';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  apiURL = `${environment.apiURL}user/tutor/login-admin`;
  apiURL2 = `${environment.apiURL}user/tutor/tutorsesion`;

  constructor(private auth: AuthService,
    private http: HttpClient) { }

  authenticate(user: number, pass: string) {
    const crypt = new Crypt();
    const token = JSON.parse(this.auth.getToken());
    const passEnc = crypt.encrypt(pass);
    const data = { user, pass: passEnc };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Amz-Security-Token': token.jwtToken,
      })
    };

    return this.http.post<Tutor>(this.apiURL, data, { observe: 'response' });

  }

conecta(idUser, idConecta){  
  const data = {idUser,idConecta};    
  return this.http.post(this.apiURL2, data, { observe: 'response' });
}


}
