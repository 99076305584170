// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  secret_key: 'J0v3n35_K3y?53Cr3T#W0rD_s3cur1ty',
  // dev
  //apiURL: 'https://71bnbidi13.execute-api.us-east-1.amazonaws.com/v1/api/jcf/',
  // qa
  //apiURL: 'https://jprodgl6s1.execute-api.us-east-1.amazonaws.com/v1/api/jcf/',
  // prod
  apiURL: 'https://7n0s15ab5m.execute-api.us-east-1.amazonaws.com/v1/api/jcf/',
  cognitoSettings: {
    UserPoolId: 'us-east-1_2b2RQN9b7',
    ClientId: '55li0gh6rl3acd3fp5t2vrmcvv'
  },
  awsConfig: {
    // dev
    //accessKeyId: 'AKIA6CTKSFN3ZVCSHKGI',
    //secretAccessKey: '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',
    // qa
    //accessKeyId: 'AKIAQMZTAALO35NIZNAX',
    //secretAccessKey: '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',
    // prod
    accessKeyId: 'AKIA6MTF3LSS5ORQYDZK',
    secretAccessKey: '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',
    region: 'us-east-1'
  },
  // dev
  //bucket: 'upax-conekta-dev',
  // qa
  //bucket: 'upax-jcf-qa',
  // prod
  bucket: 'upax-jcf-prod',
  auth: {
    user: 'jcf-admin-web-user',
    secret: 'W3bP4$$w0rdJCFSecur3d.'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

