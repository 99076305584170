import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { User } from 'src/app/model/user';
import { CargaArchivoService } from 'src/app/services/carga-archivo.service';
import { ListaCargaMasivaService } from 'src/app/services/lista-carga-masiva.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { CargaMasiva } from './../../model/CargaMasiva';
import { CargaUsuariosService } from './../../services/carga-usuarios.service';
import { TestService } from 'src/app/services/test.service';

@Component({
  selector: 'app-cargar-registros',
  templateUrl: './cargar-registros.component.html',
  styleUrls: ['./cargar-registros.component.scss']
})
export class CargarRegistrosComponent implements OnInit {

  @ViewChild('errorListaSwal') private errorListaSwal: SwalComponent;
  @ViewChild('errorArchivoSwal') private errorArchivoSwal: SwalComponent;
  @ViewChild('okArchivoSwal') private okArchivoSwal: SwalComponent;
  @ViewChild('fileCargaMasiva') fileCargaMasiva: ElementRef;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('tipo') tipo: ElementRef;
  @ViewChild('tipoAlert') tipoAlert: ElementRef;

  cargaMasivaForm: FormGroup;

  listado: CargaMasiva[];
  listadoSize = 0;
  p = 1;
  fileName: string;
  user: User;
  fileSelected = false;
  subscription: any;

  constructor(private listaCargaMasiva: ListaCargaMasivaService,
              private localStorage: LocalStorageService,
              private uploadFileService: CargaArchivoService,
              private ngxService: NgxUiLoaderService,
              private sanitizer: DomSanitizer,
              private cargaUsuariosService: CargaUsuariosService,
              private testService: TestService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('USER'));
    this.ngxService.start();
    this.getListado(this.user.idEmpresa);
    this.cargaMasivaForm = new FormGroup({
      fileCargaMasiva: new FormControl('', [Validators.required])
    });

    this.subscription = this.testService.subject.subscribe((data: any) => {
      this.ngxService.start();
      this.user = data;
      this.getListado(this.user.idEmpresa);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Obtiene el listado de archivos previamente cargados
   */
  getListado(idEmpresa: number) {

    this.listaCargaMasiva.getListaCargaMasiva(idEmpresa, this.tipo.nativeElement.value)
      .subscribe(res => {
        this.listado = res.body;
        this.ngxService.stop();
        this.listadoSize = this.listado.length;
      },
        err => {
          this.ngxService.stop();
          this.errorListaSwal.show();
        });
  }

  changeTipo() {
    this.ngxService.start();
    this.listaCargaMasiva.getListaCargaMasiva(this.localStorage.getUser().idEmpresa, this.tipo.nativeElement.value)
      .subscribe(res => {
        this.listado = res.body;
        this.ngxService.stop();
        this.listadoSize = this.listado.length;
      },
        err => {
          this.ngxService.stop();
          this.errorListaSwal.show();
        });

  }

  submitFile() {
    const file = this.cargaMasivaForm.value.fileCargaMasiva;
    if (file) {
      this.closeBtn.nativeElement.click();
      this.ngxService.start();
      const tipoA = this.tipoAlert.nativeElement.value;
      this.uploadFileService.uploadFile(file, this.fileName).subscribe(
        res => {
          this.cargaUsuariosService
            .procesaArchivo(tipoA, this.user.idUsuario, JSON.parse(JSON.stringify(res)).name, this.localStorage.getUser().idEmpresa)
            .subscribe(res2 => {
              this.cargaMasivaForm.patchValue({
                fileCargaMasiva: null
              });
              this.fileName = '';
              this.fileCargaMasiva.nativeElement.value = '';
              this.ngxService.stop();
              this.okArchivoSwal.show();
              this.getListado(this.user.idEmpresa);

            }, err2 => {
              console.log('error2: ' + err2);
              this.ngxService.stop();
              this.errorArchivoSwal.show();
            });

        }, err => {
          console.log('error1');
          this.ngxService.stop();
          this.errorArchivoSwal.show();
        }
      );
    } else {
      console.log('Debe cargar un archivo');
    }
  }

  loadCargaMasiva(event) {

    const reader = new FileReader();
    const target = event.target || event.srcElement || event.currentTarget;

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.cargaMasivaForm.patchValue({
          fileCargaMasiva: reader.result
        });
        this.fileName = this.fileCargaMasiva.nativeElement.files[0].name;
        this.fileSelected = true;
      };
    }
  }

  checkUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
