import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { BecarioAsistencia } from 'src/app/model/BecarioAsistencia';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BecarioService } from 'src/app/services/becario/becario.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { User } from 'src/app/model/user';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-ficha-justificacion',
  templateUrl: './ficha-justificacion.component.html',
  styleUrls: ['./ficha-justificacion.component.sass']
})
export class FichaJustificacionComponent implements OnInit {

  @ViewChild('closeJustificacionDialog') closeJustificacionDialog: ElementRef;
  @ViewChild('btnCancel') btnCancel: ElementRef;

  @Input() registro: BecarioAsistencia;
  @Output() errorEvent = new EventEmitter();
  @Output() okEvent = new EventEmitter();

  ASISTENCIA = 1;
  FALTA = 2;
  RETARDO = 3;
  JUSTIFICADO = 4;
  NO_LABORAL = 5;

  justificaForm: FormGroup;
  user: User;
  submitedJustificacion = false;

  constructor(private formBuilder: FormBuilder,
    private becarioService: BecarioService,
    private ngxService: NgxUiLoaderService,
    private localstorageService: LocalStorageService) { }

  ngOnInit() {

    this.user = this.localstorageService.getUser();

    this.justificaForm = this.formBuilder.group({
      fieldComentarios: ['', Validators.required],
    });
  }

  justificaBecario() {
    this.submitedJustificacion = true;
    if (this.justificaForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      this.becarioService.registroIncidencia(
        this.registro.idAsistencia,
        this.justificaForm.get('fieldComentarios').value,
        this.user.username,
        (this.registro.estatus === this.ASISTENCIA) ? this.FALTA : this.JUSTIFICADO).subscribe(
          res => {
            this.justificaForm.reset();
            this.submitedJustificacion = false;
            this.btnCancel.nativeElement.click();
            this.cerrarFicha();
            this.ngxService.stop();
            this.okEvent.emit();
          }, err => {
            this.errorEvent.emit();
            this.ngxService.stop();
          }
        );
    }
  }

  cerrarFicha() {
    this.closeJustificacionDialog.nativeElement.click();
  }

  get f() { return this.justificaForm.controls; }

}
