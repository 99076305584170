export class PlanCentroVO {
  idPlanCentro: number;
  idEntidad: number;
  entidad: string;
  idEmpresa: number;
  empresa: string;
  idCentro: number;
  centro: string;
  idPlan: number;
  plan: string;
  idTutor: number;
  tutor: string;
  ofertados: number;
  ocupados: number;
  dias: string;
  horario: string;
  idEstatus: number;
  estatus: string;
  user_modifica: string;

}
