import { PlanCentroService } from 'src/app/services/plancentro/plan-centro.service';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { PlanCentroVO } from 'src/app/model/PlanCentroVO';
import { Buscador } from 'src/app/utils/buscador';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-plancentro-tabla',
  templateUrl: './plancentro-tabla.component.html',
  styleUrls: ['./plancentro-tabla.component.sass']
})
export class PlancentroTablaComponent implements OnInit {

  @ViewChild('term') term: ElementRef;
  @ViewChild('numRegistrosElem') numRegistrosElem: ElementRef;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;

  @Input() private idEmpresa: number;
  @Input() private updateTable: EventEmitter<boolean>;
  @Output() private verEvent = new EventEmitter<PlanCentroVO>();
  @Output() private editarEvent = new EventEmitter<PlanCentroVO>();
  @Output() private eliminarEvent = new EventEmitter<PlanCentroVO>();


  plancentros: Array<{ [key: string]: any }>;
  plancentrosO: Array<{ [key: string]: any }>;
  numRegistros = 10;
  idPaginator: string;
  buscador = new Buscador();
  planCentro: PlanCentroVO;

  constructor(private planCentroService: PlanCentroService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.ngxService.start();
    this.idPaginator = 'idPager';
    this.plancentros = new Array();
    this.plancentrosO = new Array();

    this.actualizaTabla();
    this.updateTable.subscribe(res => {
      this.actualizaTabla();
    });
  }

  actualizaTabla() {
    this.planCentroService.getPlanCentroListado(this.idEmpresa).subscribe(
      res => {
        this.plancentros = res.body;
        this.plancentrosO = res.body;
        this.ngxService.stop();
      }, error => {
        console.log(error);
        this.ngxService.stop();
      }
    );
  }

  showPlanCentro(pc) {
    this.verEvent.emit(pc);
  }

  editarPlanCentro(pc) {
    this.editarEvent.emit(pc);
  }

  updateNumRegistros() {
    this.numRegistros = this.numRegistrosElem.nativeElement.value;
  }

  confirmaEliminar(pc) {
    this.eliminarEvent.emit(pc);
  }

  abrirNuevo() {
    this.planCentro = new PlanCentroVO();
    this.editarEvent.emit(this.planCentro);
  }

  descargaArchivo() {
    this.ngxService.start();
    this.planCentroService.downloadArchivo(this.idEmpresa, 5).subscribe(
      res => {
        window.open(res.body['urlInicial'], '_blank');
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
      }
    );
  }

  /**
   * Filtra de acuerdo a los parámetros recibidos
   */
  filtra() {
    const term = this.term.nativeElement.value;
    this.plancentros = this.buscador.filter(this.plancentrosO, term);
  }

}
