import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitoUtils } from '../utils/cognito-utils';
import { AuthService } from './auth.service';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  session: CognitoUserSession;
  cognitoAdminService: AWS.CognitoIdentityServiceProvider;
  userPool: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthService
  ) {
    this.cognitoAdminService = new AWS.CognitoIdentityServiceProvider({
      accessKeyId: environment.awsConfig.accessKeyId,
      secretAccessKey: environment.awsConfig.secretAccessKey,
      region: environment.awsConfig.region
    });
    this.userPool = CognitoUtils.getUserPool();
  }

  public login(login: string, password: string): Observable<boolean> {
    const cognitoUser = new CognitoUser(this.getUserData(login));
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    const authenticationDetails = new AuthenticationDetails(
      CognitoUtils.getAuthDetails(login, password)
    );

    const observe = new Observable<boolean>(
    obs => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          this.session = result;
          const token = result.getIdToken();
          const accessToken = result.getAccessToken();
          this.auth.setToken(token);
          this.auth.setAccessToken(accessToken);
          obs.next(true);
          // this.router.navigateByUrl('dashboard');
        },
        onFailure: err => {
          console.error(err);
          obs.next(false);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          delete userAttributes.email_verified;

          cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
            onSuccess: result => {
              console.log('Confirmó el correo');

            },
            onFailure: err => {
              console.log('Falló al confirmar el correo');

            }
          });
          // this.router.navigate(['login', { username: login }]);
          // obs.next(false);
        }
      });
    });
    return observe;
  }

  private getUserData(email: string) {
    return {
      Username: email,
      Pool: this.userPool
    };
  }

  public addUser(newUser: User): Observable<Object> {
    0;
    return Observable.create(obs => {
      const attrs = CognitoUtils.createNewUserAttributes(newUser);
      const cognitoUser = new CognitoUser(this.getUserData(newUser.username));
      this.userPool.signUp(
        newUser.username,
        newUser.password,
        attrs,
        [],
        (error, data) => {
          if (error) {
            console.error(error);
            obs.next(false);
            return;
          }
          this.cognitoAdminService.adminConfirmSignUp(
            {
              Username: newUser.username,
              UserPoolId: this.userPool.getUserPoolId()
            },
            (e, d) => this.defaultAdminCallback(e, d, obs)
          );
        }
      );
    });
  }

  private defaultAdminCallback(
    error,
    data,
    obs,
    ok: any = true,
    no: any = false
  ) {
    if (error) {
      console.error(error);
      obs.next(no);
      return;
    }
    obs.next(ok);
  }
}
