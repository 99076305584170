export class Becario {
  idBecario: number;
  nombre: string;
  aPaterno: string;
  aMaterno: string;
  curp: string;
  correo: string;
  telefono1: string;
  tipoTel1: string;
  telefono2: string;
  tipoTel2: string;
  nombreContacto: string;
  entidad: string;
  folio: number;
  idEstatus: number;
  selfie: string;
  idEmpresa: number;
  empresa: string;
  direccion: string;
  nombreCompleto: string;
  calle: string;
  numExt: string;
  numInt: string;
  colonia: string;
  municipio: string;
  cp: number;
  idEntidad: number;
  centro: string;
  idCentro: number;
  entidadCentro: string;
  plan: string;
  tutor: string;
  idTutor: number;
  fechaIngreso: Date;
  fechaPostulacion: Date;
  fechaAceptacion: Date;
  periodoCurso: string;
  ultimaActualizacion: Date;
  estatus: string;
  motivo: string;
  observaciones: string;
  alerta: number;  
  idEstatusPostulacion: number;
}
