import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { PlanResumen } from 'src/app/model/PlanResumen';
import { Tutor } from 'src/app/model/tutor';
import { Buscador } from 'src/app/utils/buscador';
import { TutorService } from 'src/app/services/tutores/tutor.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TutorVO } from 'src/app/model/TutorVO';

@Component({
  selector: 'app-tabla-tutores',
  templateUrl: './tabla-tutores.component.html',
  styleUrls: ['./tabla-tutores.component.scss']
})
export class TablaTutoresComponent implements OnInit {

  @ViewChild('term') term: ElementRef;
  @ViewChild('numRegistrosElem') numRegistrosElem: ElementRef;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;

  @Input() private idEmpresa: number;
  @Input() private updateTable: EventEmitter<boolean>;
  @Output() private verEvent = new EventEmitter<TutorVO>();
  @Output() private editarEvent = new EventEmitter<TutorVO>();
  @Output() private eliminarEvent = new EventEmitter<TutorVO>();


  tutores: Array<{ [key: string]: any }>;
  tutoresO: Array<{ [key: string]: any }>;
  numRegistros = 10;
  idPaginator: string;
  buscador = new Buscador();
  tutor: TutorVO;

  constructor(private tutoresService: TutorService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {

    this.ngxService.start();
    this.idPaginator = 'idPager';
    this.tutores = new Array();
    this.tutoresO = new Array();

    this.actualizaTabla();
    this.updateTable.subscribe(res => {
      this.actualizaTabla();
    });

  }

  actualizaTabla() {
    this.tutoresService.getTutoresListado(this.idEmpresa).subscribe(
      res => {
        this.tutores = res.body;
        this.tutoresO = res.body;
        this.ngxService.stop();
      }, error => {
        console.log(error);
        this.ngxService.stop();
      }
    );
  }

  showTutor(tutor) {
    this.verEvent.emit(tutor);
  }

  editarTutor(tutor) {
    this.editarEvent.emit(tutor);
  }

  updateNumRegistros() {
    this.numRegistros = this.numRegistrosElem.nativeElement.value;
  }

  confirmaEliminar(tutor) {
    this.eliminarEvent.emit(tutor);
  }

  abrirNuevo() {
    this.tutor = new TutorVO();
    this.editarEvent.emit(this.tutor);
  }

  descargaArchivo() {
    this.ngxService.start();
    this.tutoresService.downloadArchivo(this.idEmpresa, 1).subscribe(
      res => {
        window.open(res.body['urlInicial'], '_blank');
        this.ngxService.stop();
      }, err => {
        this.ngxService.stop();
      }
    );
  }

  /**
   * Filtra de acuerdo a los parámetros recibidos
   */
  filtra() {
    const term = this.term.nativeElement.value;
    this.tutores = this.buscador.filter(this.tutoresO, term);
  }

}
