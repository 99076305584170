import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { DeclinarService } from 'src/app/services/postulantes/declinar.service';
import { MotivoDeclinacion } from 'src/app/model/MotivoDeclinacion';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Declinacion } from 'src/app/model/Declinacion';

@Component({
  selector: 'app-ficha-declinacion',
  templateUrl: './ficha-declinacion.component.html',
  styleUrls: ['./ficha-declinacion.component.scss']
})
export class FichaDeclinacionComponent implements OnInit {

  @ViewChild('closeDeclinaDialog') closeDeclinaDialog: ElementRef;

  @Output() declinaEvent = new EventEmitter();

  motivos: MotivoDeclinacion[];
  declinaForm: FormGroup;
  submitedDeclina = false;
  declinacion: Declinacion;

  constructor(private declinarService: DeclinarService,
    private formBuilder: FormBuilder, ) { }

  ngOnInit() {
    this.declinaForm = this.formBuilder.group({
      fieldMotivo: ['', Validators.required],
      fieldComentarios: ['', Validators.required],
    });
    this.declinarService.getMotivosDeclinacion( false, true ).subscribe(
      res => {
        this.motivos = res.body;
      }, error => {

      }
    );
  }

  cierraModalDeclinacion() {
    this.submitedDeclina = false;
    this.declinaForm.get('fieldMotivo').setValue('');
    this.declinaForm.get('fieldComentarios').setValue('');
    this.closeDeclinaDialog.nativeElement.click();
  }

  declinaPostulante() {
    this.submitedDeclina = true;
    if (this.declinaForm.invalid) {
      return;
    } else {
      this.declinacion = new Declinacion();
      this.declinacion.idMotivo = this.declinaForm.get('fieldMotivo').value;
      this.declinacion.comentarios = this.declinaForm.get('fieldComentarios').value;
      this.cierraModalDeclinacion();
      this.declinaEvent.emit(this.declinacion);
    }
  }

  get f() { return this.declinaForm.controls; }

}
