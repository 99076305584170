import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CargaUsuariosService {

  urlBecarios = `${environment.apiURL}cargasmasivas/becario`;
  urlCentro = `${environment.apiURL}cargasmasivas/centro`;
  urlPlan = `${environment.apiURL}cargasmasivas/plancapacitacion`;
  urlTutores = `${environment.apiURL}cargasmasivas/tutor`;

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService) { }

  procesaArchivo(tipo, idUser, nombreArchivo, idEmpresa) {

    const user = this.localStorageService.getUser();
    let url: string;
    let idPerfil: number;
    const folioEmpresa = user.idFolio;

    switch (tipo) {
      case '1':
        url = this.urlBecarios;
        idPerfil = 1;
        break;
      case '2':
        url = this.urlTutores;
        idPerfil = 2;
        break;
      case '3':
        url = this.urlCentro;
        break;
      case '4':
        url = this.urlPlan;
        break;
    }

    const data = { idUser, nombreArchivo, idEmpresa, opcion: tipo, idPerfil, folioEmpresa, userModifica: idUser + '' };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        // 'X-Amz-Security-Token': token.jwtToken,
      })
    };

    return this.http
      .post(url, data, httpOptions);
  }

}
