import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Declinacion } from 'src/app/model/Declinacion';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccionesService {

  apiURLAceptar = `${environment.apiURL}postulacion/change-estatus/web`;
  apiURLDeclinar = `${environment.apiURL}postulacion/declinar`;

  constructor(private http: HttpClient) { }

  /**
   * Acepta al postulante, actualiza el estatus
   *
   * @param idUsuario
   * @param idEstatus
   * @param userModifica
   */
  aceptaPostulante(idUsuario: number, idTutor: number, idEstatus: number, userModifica: string) {
    let nuevoEstatus: number;

    if (idEstatus == 3 || idEstatus == 2) {
      nuevoEstatus = 6;
    } else if (idEstatus == 6) {
      nuevoEstatus = 1;
    }
    const data = { idUsuario, idTutor, idEstatus: nuevoEstatus, userModifica };
    return this.http.post<any>(this.apiURLAceptar, data, { observe: 'response' });
  }

  /**
   * Declina al becario
   * @param declinacion
   */
  declinar(declinacion: Declinacion) {
    return this.http.post(this.apiURLDeclinar, declinacion, { observe: 'response' });
  }

}
