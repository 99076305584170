import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TipoEscolaridad } from 'src/app/model/TipoEscolaridad';

@Injectable({
  providedIn: 'root'
})
export class TipoEscolaridadService {

  apiURL = `${environment.apiURL}catalogos/tipos-escolaridad`;

  constructor(private http: HttpClient) { }

  getTiposEscolaridad(idEstatus: number) {
    const data = { idEstatus };
    return this.http.post<TipoEscolaridad[]>(this.apiURL, data, { observe: 'response' });
  }
}
