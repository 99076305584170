import { Pipe, PipeTransform } from '@angular/core';
import { ValueTransformer } from '@angular/compiler/src/util';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchTerm: string, labelKey?: string): any {

    if (!items || !searchTerm) {
      return items;
    }

    const values = items.filter(
      item =>
        item[labelKey || 'nombre']
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) === true
    );

    return values;
  }

}
