import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { User } from 'src/app/model/user';
import { Empresa } from 'src/app/model/Empresa';
import { LoginService } from 'src/app/services/login.service';
import { TestService } from 'src/app/services/test.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.sass']
})
export class UsuarioComponent implements OnInit {

  @Input() empresas: Array<Empresa>;

  user: User;
  company = new Empresa();

  constructor(public dialogRef: MatDialogRef<UsuarioComponent>,
              private localstorageService: LocalStorageService,
              private router: Router,
              private testService: TestService,
              private loginService: LoginService) { }

  ngOnInit() {
    this.user = this.localstorageService.getUser();
    this.company.idEmpresa = this.user.idEmpresa;
  }

  guardarUsuario() {
    this.user.idEmpresa = this.company.idEmpresa;
    this.user.nomEmpresa = this.empresas[this.company.idEmpresa].nombreEmpresa;

    this.testService.setData(this.user);
    this.localstorageService.modifyLocalUser(this.user);
    this.dialogRef.close();
  }

  cerrarSesion() {
    this.loginService.conecta(localStorage.getItem('id') ,0).subscribe(
      res3 => {

        }, error => {
          console.log("ERROR");
        }
    )
    this.localstorageService.deleteSession();
    this.dialogRef.close();
    this.router.navigateByUrl('login');
  }

  close() {
    this.dialogRef.close();
  }
}
