import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PlanCentroVO } from 'src/app/model/PlanCentroVO';
import { User } from 'src/app/model/user';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PlanCentroService } from 'src/app/services/plancentro/plan-centro.service';

@Component({
  selector: 'app-plancentro-listado',
  templateUrl: './plancentro-listado.component.html',
  styleUrls: ['./plancentro-listado.component.sass']
})
export class PlancentroListadoComponent implements OnInit {

  @ViewChild('okCreaPCSwal') private okCreaPCSwal: SwalComponent;
  @ViewChild('okModificaPCSwal') private okModificaPCSwal: SwalComponent;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  @ViewChild('eliminarPCSwal') private eliminarPCSwal: SwalComponent;
  @ViewChild('activarPCSwal') private activarPCSwal: SwalComponent;
  @ViewChild('okEliminaPCSwal') private okEliminaPCSwal: SwalComponent;
  @ViewChild('okActivaPCSwal') private okActivaPCSwal: SwalComponent;
  @ViewChild('errorEliminarPCSwal') private errorEliminarPCSwal: SwalComponent;

  changePlanCentro: EventEmitter<PlanCentroVO> = new EventEmitter();
  updateEvent: EventEmitter<boolean> = new EventEmitter();

  user: User;
  planCentro = new PlanCentroVO();
  editar = false;

  constructor(private localstorageService: LocalStorageService,
    private planCentroService: PlanCentroService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.user = this.localstorageService.getUser();
  }

  verPlanCentro($event) {
    this.planCentro = $event;
    this.editar = false;

    this.changePlanCentro.emit(this.planCentro);
  }

  editarPlanCentro($event) {
    this.planCentro = $event;
    this.editar = true;
    this.changePlanCentro.emit(this.planCentro);
  }

  confirmaEliminarPlanCentro($event) {
    this.planCentro = $event;
    if (this.planCentro.idEstatus === 1) {
      this.eliminarPCSwal.show();
    } else {
      this.activarPCSwal.show();
    }
  }

  updatePlanCentro($event: PlanCentroVO) {
    let tipo: number;
    if (this.editar && $event.idPlanCentro) {
      tipo = 1;
    } else {
      tipo = 2;
    }

    $event.idEmpresa = this.user.idEmpresa;
    $event.user_modifica = this.user.username;

    this.planCentroService.updatePlanCentro($event, tipo).subscribe(
      res => {
        if (tipo === 2) {
          this.okCreaPCSwal.show();
        } else if (tipo === 1) {
          this.okModificaPCSwal.show();
        }
        this.ngxService.stop();
        this.updateEvent.emit(true);
      }, error => {
        this.errorSwal.show();
        this.ngxService.stop();
        this.updateEvent.emit(true);
      }
    );

  }

}
